import React from 'react';
import { Bar } from 'react-chartjs-2';



const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
const data = {
  labels: labels,
  datasets: [
    {
      label: 'Fully Rounded',
      data: [12, 34, 12, 56, 78, 34, 56],
      backgroundColor: 'rgb(255, 99, 132)',
      borderWidth: 2,
      borderRadius: Number.MAX_VALUE,
      borderSkipped: false,
    },
    {
      label: 'Small Radius',
      data: [56, 45, 23, 12, 87, 56, 65],
      backgroundColor: 'rgb(54, 162, 235)',
      borderWidth: 2,
      borderRadius: 5,
      borderSkipped: false,
    }
  ]
};


const Report2=()=>{

    return (
        <>
            <Bar
            data= {data}
            options= {
              {responsive: true,
                legend: {
                    position: 'bottom',
                    display: true,
                    labels: {
                        fontSize: 8,
                        usePointStyle: true,
                        boxWidth: 6
                    }
                  },
              plugins: {
                legend: {
                  position: 'left',
                  display: true,
                },
                title: {
                  display: false,
                  text: 'Chart.js Bar Chart'
                }
              }}
            }
            />
        </>
    )

}

export default Report2;
