import React, { useEffect } from "react";
import Dashboard from "../pages/dashboard/dashboard";

const Panel = () => {

  useEffect(()=>{
    const reloadCount = sessionStorage.getItem('reloadCount');
        if(reloadCount < 1) {
        sessionStorage.setItem('reloadCount', String(reloadCount + 1));
        window.location.reload();
        } else {
        sessionStorage.removeItem('reloadCount');
        }
  })

  
  return (
    <>
    <Dashboard />
    </>
    
  );
};

export default Panel;
