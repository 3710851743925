import React, { useState } from "react";
import useAuth from "../hooks/useAuth";
import {Button} from 'reactstrap'
import useAuthAction from "../hooks/useAuthAction";
import { useHistory, Redirect } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import { Link } from "react-router-dom";
import axios from 'axios'

const Login = () => {
  const setToken = useAuthAction();
  const history = useHistory();
  const isLogged = useAuth();

  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})

  const handleChange=(e)=>{
    if(e.target.name === 'user'){
      setUser(e.target.value)
    }
    if(e.target.name === 'password'){
      setPassword(e.target.value)
    }
  }

  const handleValidation=()=> {
    let errors = {};
    let formIsValid = true;

    if( user === ''){
      formIsValid = false;
      errors["user_error"] = "Please Enter User";
    }
    if(password === ''){
      formIsValid = false;
      errors["pass_error"] = "Please Enter Password";
    }

    setErrors(errors)
    return formIsValid;
  }


  const handelLogin = () => {
    const headers = {headers: {  'Content-Type': 'application/x-www-form-urlencoded'}}

    const isValid = handleValidation();
		if (isValid) {
			axios
				.post('https://blablastay-admin-api-dev-net.azurewebsites.net/' + 'connect/token', {
					// grant_type:'password',
          // scope:'ConnectApi',
          // client_id:'WebUI.Admin',
          // client_secret:'eNuscFFwA8X4tJEYsQyjndUkrQhxN3Xu',
          // Username:'connectadmin@yopmail.com',
          // Password:'Pass@123',

          grant_type:'password',
          scope:'ConnectApi',
          client_id:'WebUI.Admin',
          client_secret:'eNuscFFwA8X4tJEYsQyjndUkrQhxN3Xu',
          Username:user,
          Password: password,

				}, headers)
				.then(response => {
          console.log(response, 'response')
          setToken(response.data.access_token);
          localStorage.setItem('Token', response.data.access_token)
          history.push("/");
				});
		}

  };
  return (
    <>
     {isLogged ? <Redirect to="/" /> : null}


    {/* login page */}
    <header className="login-header">
          <h4 className="text-white p-4" >Bla Bla Stay</h4>
    </header>
    <div className="container-fluid">

      <section className="section register d-flex flex-column align-items-center justify-content-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

              
              {/* <!-- End Logo --> */}

              <div className=" mb-3">

                <div className="card-body my-4">
              <div class="pt-4 pb-2">
                    <h5 class="card-title text-start pb-0 fs-4 text-color">Login to Your Account</h5>
                    <p class="text-start">Enter your username &amp; password to login</p>
                  </div>

                  <div className="row g-3">

                    <div className="col-12">
                      <div className="position-relative">
                      <input type="text" name="user" value={user} className="form-control" placeholder='Enter User' onChange={handleChange} />
                      </div>
                        <div className="text-danger small">{errors["user_error"]}</div>
                    </div>

                    <div className="col-12">
                      <div className="position-relative">
                      <input type="password" name="password" value={password} placeholder='Enter Password' className="form-control" onChange={handleChange} />
                      </div>
                      
                      <div className="text-danger small">{errors["pass_error"]}</div>
                    </div>
                    <div className="col-12 mt-0 text-start">
                        <small className="text-muted font-10">Forgot Password ?</small>
                    </div>
                    <div className="col-12 mt-4">
                      <Button
                      onClick={() => handelLogin()}
                      className="w-100 login-btn"
                      variant="contained"
                      color="danger"
                    >
                      Login
                    </Button>
                   
                    </div>
                    <div class="col-12 mt-3">
                      <p class="fw-bold mb-0 text-start">Don't have account? <Link to="/Register">Create an account</Link></p>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>

      </section>

    </div>
    {/* login page */}




     
    

    {/* <Button onClick={() => history.push("/")} color="primary">
        Switch To Panel
      </Button> */}
    </>
   
  );
};

export default Login;
