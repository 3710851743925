import React, {useEffect, useState} from "react";
import PageTitle from "./pageTitle";
import CommonMaterialTable from "../common/materialTable";
import { Button, Input, Label, Modal, ModalBody, ModalHeader, ModalFooter, Row, Col } from 'reactstrap';
import ReactStars from 'react-stars'

const PropertyReview=()=>{

    const [totalCount, setTotalCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [isSlideModal, setIsSlideModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [rating, setRating] = useState(0)

    const [data, setData] = useState([])
    const ratingChanged = (newRating) => {
        console.log(newRating)
      }

    const handleChange=()=>{

    }

    const handleCloseModal=()=>{
        setModal(!modal)
    }
    const handleOpenModal=()=>{
        setModal(!modal)
    }

    const handleCloseSlideBox=()=>{
        setIsSlideModal(! isSlideModal)
    }

    const handleOpenSlideBox=()=>{
        setIsSlideModal(! isSlideModal)
    }


    const columns=[
        { title: '#', field: 'sno', headerStyle: { borderLeft: '0px', paddingLeft: 20, width: 50 } },
        { title: 'Review From', field: 'name' },
        { title: 'Name', field: 'name' },
        { title: 'Image', field: 'image' },
        { title: 'Title', field: 'title' },
        { title: 'Review', field: 'review' },
        { title: 'Display', field: 'display' },
        { title: 'Rating', field: 'rating' },
        { title: 'Date', field: 'date' },
        { title: 'Reply', field: 'reply' },
        { title: 'Action', field: 'action' }
    ]

    return (
        <>
            <PageTitle title={'Review'} />
            <div className='card'>
                <div className='card-body mt-4'>
                    <span className='table-add-icon c-pointer' title='Add' onClick={handleOpenModal}><i class="bi bi-list-stars"></i></span>
                    <span className='table-filter-icon c-pointer' title='Filter' onClick={handleOpenSlideBox}><i class="bi bi-funnel"></i></span>

                    <CommonMaterialTable data={data} columns={columns} totalCount={totalCount}  rowsPerPage={rowsPerPage}/>
                    
                </div>
                
            </div>

            {
            isSlideModal ?
            <div className='slider-box'>
            <div className='slider-box-title'>
            <span className='text-primary'><b>Filter</b></span>
                <span className='text-danger fw-bold c-pointer float-end' onClick={handleCloseSlideBox}>X</span>
            </div>
            <div className='slider-box-body'>
                <Label className='text-muted fw-bold'>Name</Label>
                <Input type='text' value='' name='' onChange={handleChange} />
                <Label className='mt-3 text-muted fw-bold'>Location</Label>
                <Input type='text' value='' name='' onChange={handleChange} />
                <Label className='mt-3 text-muted fw-bold'>Status</Label>
                <Input type='select' value='' name='' onChange={handleChange} >
                    <option value=''></option>
                    <option value='Available'>Available</option>
                    <option value='Booked'>Booked</option>
                    <option value='Block'>Block</option>
                </Input>
            </div>
            <div className='slider-box-footer text-center mt-4'>
                <Button color='primary' block>Filter</Button>
            </div>
        </div> : null
        }

<Modal isOpen={modal} size='md'>
            <ModalHeader toggle={handleCloseModal}>Add Review</ModalHeader>
            <ModalBody>
            
                <Row>
                    <Col sm={6}>
                        <Label>Guest Name</Label>
                        <Input name='name' onClick={handleChange} />
                    </Col>
                    <Col sm={6}>
                        <Label>Guest Image URL</Label>
                        <Input  name='propertyCode' onClick={handleChange} />
                    </Col>
                    <Col sm={6}>
                        <Label>Title</Label>
                        <Input name='propertyCodeName' onClick={handleChange} />
                    </Col>
                    <Col sm={6}>
                        <Label>Review Resource</Label>
                        <Input type='select'  name='propertyContactPerson' onClick={handleChange}>
                            <option value=''>Select</option>
                            <option value='facebook'>Facebook</option>
                            <option value='google'>Google</option>
                            <option value='instagram'>Instagram</option>
                            <option value='myrentalstay'>Myrentalstay</option>
                        </Input>
                    </Col>



                    <Col sm={12}>
                        <Label>Review</Label>
                        <Input type='textarea' name='propertyContactPersonMobile' onClick={handleChange} />
                    </Col>
                    <Col sm={4}>
                        <Label>Rating</Label>
                        <ReactStars
                            count={5}
                            onChange={ratingChanged}
                            size={24}
                            color2={'#ffd700'} />
                    </Col>
                    <Col sm={4}>
                        <Label>Date Time</Label>
                        <Input type="date" name='propertyState' onClick={handleChange} />
                    </Col>
                    <Col sm={4}>
                        <Label>Verified</Label>
                        <Input name='propertyCity' onClick={handleChange} >
                            <option value=''>Select</option>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                        </Input>
                    </Col>
                
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color='danger' className='mr-1'>Cancel</Button>
                <Button color='primary'>Save</Button>
            </ModalFooter>
        </Modal>
        </>
    )
}

export default PropertyReview