import React, {useState, useEffect} from 'react'
import ProfileImg from "../../assets/images/profile-img.jpg";
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader } from 'react-pro-sidebar';
import { BsFillGridFill, BsFillHddStackFill, BsBook,BsBookHalf , BsRecordCircle,BsTv , BsBookmarksFill  , BsFillGrid3X2GapFill, BsCalendar3 ,BsPeople , BsHouse , BsHouseLock     } from "react-icons/bs";
import 'react-pro-sidebar/dist/css/styles.css';
import {Link} from 'react-router-dom'

const Header = () => {
    const [collapsedMenuFlag, setCollapsedMenuFlag] = useState(true)

    const handleCollapsedSidebar=()=>{
        setCollapsedMenuFlag(!collapsedMenuFlag)
        document.getElementById("body").classList.toggle('toggle-sidebar')
    }

    const logOut = () => {
        localStorage.clear("TOKEN");
        window.location.href = "Login";
      };

     

    return (
        <>

            <header id="header" class="header fixed-top d-flex align-items-center">

                <div class="d-flex align-items-center justify-content-between">
                    <a href="index.html" class="logo d-flex align-items-center">
                        <img src="assets/img/logo.png" alt="" />
                        <span class="">Bla Bla Stay</span>
                    </a>
                    <i class="bi bi-list toggle-sidebar-btn" onClick={handleCollapsedSidebar}></i>
                </div>

                <nav class="header-nav ms-auto">
                    <ul class="d-flex align-items-center">

                        <li class="nav-item d-block d-lg-none">
                            <a class="nav-link nav-icon search-bar-toggle " href="#">
                                <i class="bi bi-search"></i>
                            </a>
                        </li>

                        <li class="nav-item dropdown">

                            <a class="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                                <i class="bi bi-bell"></i>
                                <span class="badge bg-primary badge-number">4</span>
                            </a>

                            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                                <li class="dropdown-header">
                                    You have 4 new notifications
                                    <a href="#"><span class="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                                </li>
                                <li>
                                    <hr class="dropdown-divider" />
                                </li>

                                <li class="notification-item">
                                    <i class="bi bi-exclamation-circle text-warning"></i>
                                    <div>
                                        <h4>Lorem Ipsum</h4>
                                        <p>Quae dolorem earum veritatis oditseno</p>
                                        <p>30 min. ago</p>
                                    </div>
                                </li>

                                <li>
                                    <hr class="dropdown-divider" />
                                </li>

                                <li class="notification-item">
                                    <i class="bi bi-x-circle text-danger"></i>
                                    <div>
                                        <h4>Atque rerum nesciunt</h4>
                                        <p>Quae dolorem earum veritatis oditseno</p>
                                        <p>1 hr. ago</p>
                                    </div>
                                </li>

                                <li>
                                    <hr class="dropdown-divider" />
                                </li>

                                <li class="notification-item">
                                    <i class="bi bi-check-circle text-success"></i>
                                    <div>
                                        <h4>Sit rerum fuga</h4>
                                        <p>Quae dolorem earum veritatis oditseno</p>
                                        <p>2 hrs. ago</p>
                                    </div>
                                </li>

                                <li>
                                    <hr class="dropdown-divider" />
                                </li>

                                <li class="notification-item">
                                    <i class="bi bi-info-circle text-primary"></i>
                                    <div>
                                        <h4>Dicta reprehenderit</h4>
                                        <p>Quae dolorem earum veritatis oditseno</p>
                                        <p>4 hrs. ago</p>
                                    </div>
                                </li>

                                <li>
                                    <hr class="dropdown-divider" />
                                </li>
                                <li class="dropdown-footer">
                                    <a href="#">Show all notifications</a>
                                </li>

                            </ul>

                        </li>

                        <li class="nav-item dropdown">

                            <a class="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                                <i class="bi bi-chat-left-text"></i>
                                <span class="badge bg-success badge-number">3</span>
                            </a>

                            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
                                <li class="dropdown-header">
                                    You have 3 new messages
                                    <a href="#"><span class="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                                </li>
                                <li>
                                    <hr class="dropdown-divider" />
                                </li>

                                <li class="message-item">
                                    <a href="#">
                                        <img src="assets/img/messages-1.jpg" alt="" class="rounded-circle" />
                                        <div>
                                            <h4>Maria Hudson</h4>
                                            <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                                            <p>4 hrs. ago</p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <hr class="dropdown-divider" />
                                </li>

                                <li class="message-item">
                                    <a href="#">
                                        <img src="assets/img/messages-2.jpg" alt="" class="rounded-circle" />
                                        <div>
                                            <h4>Anna Nelson</h4>
                                            <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                                            <p>6 hrs. ago</p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <hr class="dropdown-divider" />
                                </li>

                                <li class="message-item">
                                    <a href="#">
                                        <img src="assets/img/messages-3.jpg" alt="" class="rounded-circle" />
                                        <div>
                                            <h4>David Muldon</h4>
                                            <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                                            <p>8 hrs. ago</p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <hr class="dropdown-divider" />
                                </li>

                                <li class="dropdown-footer">
                                    <a href="#">Show all messages</a>
                                </li>

                            </ul>

                        </li>

                        <li class="nav-item dropdown pe-3">

                            <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                <img src={ProfileImg} alt="Profile" class="rounded-circle" />
                                <span class="d-none d-md-block dropdown-toggle ps-2">Admin</span>
                            </a>

                            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li class="dropdown-header">
                                    <h6>Admin (MD)</h6>
                                    {/* <span>MD</span> */}
                                </li>
                                <li>
                                    <hr class="dropdown-divider" />
                                </li>

                                <li>
                                    <Link class="dropdown-item d-flex align-items-center" to={`/user-register`}>
                                        <i class="bi bi-person"></i>
                                        <span>User Registration</span>
                                    </Link>
                                </li>
                                {/* <li>
                                    <hr class="dropdown-divider" />
                                </li> */}

                                {/* <li>
                                    <a class="dropdown-item d-flex align-items-center" href="users-profile.html">
                                        <i class="bi bi-gear"></i>
                                        <span>Account Settings</span>
                                    </a>
                                </li>
                                <li>
                                    <hr class="dropdown-divider" />
                                </li>

                                <li>
                                    <a class="dropdown-item d-flex align-items-center" href="pages-faq.html">
                                        <i class="bi bi-question-circle"></i>
                                        <span>Need Help?</span>
                                    </a>
                                </li> */}
                                <li>
                                    <hr class="dropdown-divider" />
                                </li>

                                <li>
                                    <a class="dropdown-item d-flex align-items-center" href="#">
                                        <i class="bi bi-box-arrow-right"></i>
                                        <span onClick={() => logOut()}>Sign Out</span>
                                    </a>
                                </li>

                            </ul>
                        </li>

                    </ul>
                </nav>

            </header>
            <ProSidebar  collapsed={collapsedMenuFlag} breakPoint='md'>
                <Menu iconShape="circle" closeOnClick={true}>
                    <MenuItem icon={<BsFillGridFill  />} title='Dashboard'>Dashboard</MenuItem>
                    <MenuItem icon={<BsFillHddStackFill  />} title='Enquiry'>Enquiry</MenuItem>
                   
                   
                    <SubMenu icon={<BsBook  />} title="Accounts">
                    <MenuItem icon={<BsRecordCircle    />} title='Dashboard'>Component 1</MenuItem>
                    <MenuItem icon={<BsRecordCircle    />} title='Dashboard'>Component 2</MenuItem>
                    </SubMenu>
                    
                    <MenuItem icon={<BsFillGrid3X2GapFill  />} title='Inventory'>Inventory</MenuItem>
                    <MenuItem icon={<BsCalendar3  />} title='Calender'>Calender</MenuItem>
                    <MenuItem icon={<BsHouseLock   />} title='Reservations'>Reservations</MenuItem>
                    <SubMenu icon={<BsHouse  />} title="Properties">
                    <MenuItem icon={<BsRecordCircle    />}><Link to={`/property-list`} title='Properties'>Properties</Link></MenuItem>
                    <MenuItem icon={<BsRecordCircle    />} title='Review'><Link to={'/review'}>Review</Link></MenuItem>
                    </SubMenu>
                    <SubMenu icon={<BsPeople  />} title="Masters">
                    <MenuItem icon={<BsRecordCircle    />} title='Dashboard'>Component 1</MenuItem>
                    <MenuItem icon={<BsRecordCircle    />} title='Dashboard'>Component 2</MenuItem>
                    </SubMenu>
                    <SubMenu icon={<BsTv  />} title="Websites">
                    <MenuItem icon={<BsRecordCircle    />} title='Dashboard'>Component 1</MenuItem>
                    <MenuItem icon={<BsRecordCircle    />} title='Dashboard'>Component 2</MenuItem>
                    </SubMenu>
                    <SubMenu icon={<BsBookmarksFill  />} title="Host Master">
                    <MenuItem icon={<BsRecordCircle    />} title='Dashboard'>Component 1</MenuItem>
                    <MenuItem icon={<BsRecordCircle    />} title='Dashboard'>Component 2</MenuItem>
                    </SubMenu>
                    <MenuItem icon={<BsRecordCircle />} title='Sub Host'>Sub Host</MenuItem>
                    
                    <SubMenu icon={<BsPeople />} title="User Management">
                    <MenuItem icon={<BsRecordCircle    />} title='Dashboard'>Component 1</MenuItem>
                    <MenuItem icon={<BsRecordCircle    />} title='Dashboard'>Component 2</MenuItem>
                    </SubMenu>
                    <MenuItem icon={<BsBookHalf  />} title='Company Profile'>Company Profile</MenuItem>
                    <SubMenu icon={<BsRecordCircle />} title="User Management">
                    <MenuItem icon={<BsRecordCircle    />} title='Dashboard'>Component 1</MenuItem>
                    <MenuItem icon={<BsRecordCircle    />} title='Dashboard'>Component 2</MenuItem>
                    </SubMenu>
                </Menu>
                </ProSidebar>
        </>
    )

}

export default Header;