import React, { useEffect, useState } from 'react'
import PageTitle from '../pageTitle';
import { Button, Input, Label, Modal, ModalBody, ModalHeader, ModalFooter, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import CommonMaterialTable from '../../common/materialTable';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import swal from 'sweetalert'
import { postUser, getUser } from '../../hooks/Interceptor';
import Select from "react-select";

const SubPropertyList=(props)=>{
    const [isSlideModal, setIsSlideModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [imageModal, setImageModal] = useState(false)
    const [infoModal, setInfoModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [settingModal, setSettingModal] = useState(false)

    const [data, setData] = useState([])
    const [pagingModel, setPagingModel] = useState()

    const [propertyTypeList, setPropertyTypeList] = useState([])

    const [errors, setErrors] = useState({})
    const [flatName, setFlatName] = useState('')
    const [flatCodeName, setFlatCodeName] = useState('')
    const [flatNo, setFlatNo] = useState('')
    const [unit, setUnit] = useState('')
    const [sharing, setSharing] = useState('')
    const [display, setDisplay] = useState('')
    const [contactPerson, setContactPerson] = useState('')
    const [conatctPersonMobile, setConatctPersonMobile] = useState('')
    const [managerName, setManagerName] = useState('')
    const [managerMobile, setManagerMobile] = useState('')
    const [hostName, setHostName] = useState('')
    const [hostMobile, setHostMobile] = useState('')
    const [hostEmail, setHostEmail] = useState('')
    const [dailyPrice, setDailyPrice] = useState('')
    const [weeklyPrice, setWeeklyPrice] = useState('')
    const [monthlyPrice, setMonthlyPrice] = useState('')
    const [extraBedPrice, setExtraBedPrice] = useState('')
    const [propertyDis, setPropertyDis] = useState('')
    const [wifi, setWifi] = useState('')
    const [passsword, setPasssword] = useState('')
    const [checkInTime, setCheckInTime] = useState('')
    const [checkInUpTo, setCheckInUpTo] = useState('')
    const [checkOutTime, setCheckOutTime] = useState('')
    const [checkOutUpTo, setCheckOutUpTo] = useState('')
    const [capacity, setCapacity] = useState('')
    const [maxGuest, setMaxGuest] = useState('')
    const [lockInPeriod, setLockInPeriod] = useState('')
    const [noticePeriod, setNoticePeriod] = useState('')
    const [deposit, setDeposit] = useState('')
    const [propertyType, setPropertyType] = useState([])
    const [propertyConfig, setPropertyConfig] = useState('')
    const [propertyConfigDetails, setPropertyConfigDetails] = useState('')
    const [guestAccess, setGuestAccess] = useState('')
    const [otherThingToNote, setOtherThingToNote] = useState('')
    const [howToReach, setHowToReach] = useState('')

    const [selectedRowID, setSelectedRowID] = useState({})

    const [settingType, setSettingType] = useState(false)

    const handleChangeType=(selectedOption)=>{
        setPropertyType(selectedOption)
    }

    const handleChange= async(e)=>{
        const name = e.target.name
        const value = e.target.value
        if(name === 'flatName'){
            setFlatName(value)
        }
        if(name === 'flatCodeName'){
            setFlatCodeName(value)
        }
        if(name === 'flatNo'){
            setFlatNo(value)
        }
        if(name === 'unit'){
            setUnit(value)
        }
        if(name === 'sharing'){
            setSharing(value)
        }
        if(name === 'display'){
            setDisplay(value)
        }
        if(name === 'contactPerson'){
            setContactPerson(value)
        }
        if(name === 'conatctPersonMobile'){
            setConatctPersonMobile(value)
        }
        if(name === 'managerName'){
            setManagerName(value)
        }
        if(name === 'managerMobile'){
            setManagerMobile(value)
        }
        if(name === 'hostName'){
            setHostName(value)
        }
        if(name === 'hostMobile'){
            setHostMobile(value)
        }
        if(name === 'hostEmail'){
            setHostEmail(value)
        }
        if(name === 'dailyPrice'){
            setDailyPrice(value)
        }
        if(name === 'weeklyPrice'){
            setWeeklyPrice(value)
        }
        if(name === 'monthlyPrice'){
            setMonthlyPrice(value)
        }
        if(name === 'extraBedPrice'){
            setExtraBedPrice(value)
        }
        if(name === 'propertyDis'){
            setPropertyDis(value)
        }
        if(name === 'wifi'){
            setWifi(value)
        }
        if(name === 'passsword'){
            setPasssword(value)
        }
        if(name === 'checkInTime'){
            setCheckInTime(value)
        }
        if(name === 'checkInUpTo'){
            setCheckInUpTo(value)
        }
        if(name === 'checkOutTime'){
            setCheckOutTime(value)
        }
        if(name === 'checkOutUpTo'){
            setCheckOutUpTo(value)
        }
        if(name === 'capacity'){
            setCapacity(value)
        }
        if(name === 'maxGuest'){
            setMaxGuest(value)
        }
        if(name === 'lockInPeriod'){
            setLockInPeriod(value)
        }
        if(name === 'noticePeriod'){
            setNoticePeriod(value)
        }
        if(name === 'deposit'){
            setDeposit(value)
        }
        if(name === 'propertyConfig'){
            setPropertyConfig(value)
        }
        if(name === 'propertyConfigDetails'){
            setPropertyConfigDetails(value)
        }
        if(name === 'guestAccess'){
            setGuestAccess(value)
        }
        if(name === 'otherThingToNote'){
            setOtherThingToNote(value)
        }
        if(name === 'howToReach'){
            setHowToReach(value)
        }
        if(name === 'settingType'){
            setSettingType(value)

            if(value === 'policy'){
                try {
                    const response = await getUser(`api/SubProperty/list-policy/${selectedRowID.subPropertyTypeId}`)
                    if(response){
                        console.log(response, 'responseresponseresponse');
                    } 
                }
                    catch (error) {
                        console.error('Error fetching user:', error);
                    }
            }
            if(value === 'amenities'){
                try {
                    const response = await getUser(`api/SubProperty/list-amenities/${selectedRowID.subPropertyTypeId}`)
                    if(response){
                        console.log(response, 'responseresponseresponse');
                    } 
                }
                    catch (error) {
                        console.error('Error fetching user:', error);
                    }
            }
            if(value === 'activity'){
                try {
                    const response = await getUser(`api/SubProperty/list-activity/${selectedRowID.subPropertyTypeId}`)
                    if(response){
                        console.log(response, 'responseresponseresponse');
                    } 
                }
                    catch (error) {
                        console.error('Error fetching user:', error);
                    }
            }
            if(value === 'sleeping arrengment'){

            }
            if(value === 'kitchen'){

            }
            if(value === 'broadband'){

            }
        }
    }

    const handleValidation=()=>{
        let errors = {};
        let formIsValid = true;

        if (flatName === '') {
            formIsValid = false;
            errors["flatName_error"] = "Please Enter Flat Name";
        }
        if (flatCodeName === '') {
            formIsValid = false;
            errors["flatCodeName_error"] = "Please Enter Flat Code Name";
        }
        if (flatNo === '') {
            formIsValid = false;
            errors["flatNo_error"] = "Please Enter Flat no";
        }
        if (unit === '') {
            formIsValid = false;
            errors["unit_error"] = "Please Enter Unit";
        }
        if (sharing === '') {
            formIsValid = false;
            errors["sharing_error"] = "Please Enter Sharing";
        }
        if (display === '') {
            formIsValid = false;
            errors["display_error"] = "Please Enter Display";
        }
        if (contactPerson === '') {
            formIsValid = false;
            errors["contactPerson_error"] = "Please Enter Contact Person";
        }
        if (conatctPersonMobile === '') {
            formIsValid = false;
            errors["conatctPersonMobile_error"] = "Please Enter Contact Person Mobile";
        }
        if (managerName === '') {
            formIsValid = false;
            errors["managerName_error"] = "Please Enter Manager Name";
        }
        if (managerMobile === '') {
            formIsValid = false;
            errors["managerMobile_error"] = "Please Enter Manager Mobile";
        }
        if (hostName === '') {
            formIsValid = false;
            errors["hostName_error"] = "Please Enter Host Name";
        }
        if (hostMobile === '') {
            formIsValid = false;
            errors["hostMobile_error"] = "Please Enter Host Mobile";
        }
        if (hostEmail === '') {
            formIsValid = false;
            errors["hostEmail_error"] = "Please Enter Host Email";
        }
        if (dailyPrice === '') {
            formIsValid = false;
            errors["dailyPrice_error"] = "Please Enter Daily Price";
        }
        if (weeklyPrice === '') {
            formIsValid = false;
            errors["weeklyPrice_error"] = "Please Enter Weekly Price";
        }
        if (monthlyPrice === '') {
            formIsValid = false;
            errors["monthlyPrice_error"] = "Please Enter Monthly Price";
        }
        if (extraBedPrice === '') {
            formIsValid = false;
            errors["extraBedPrice_error"] = "Please Enter Extra Bed Price";
        }
        if (propertyDis === '') {
            formIsValid = false;
            errors["propertyDis_error"] = "Please Enter Property Display";
        }
        if (wifi === '') {
            formIsValid = false;
            errors["wifi_error"] = "Please Enter Wifi";
        }
        if (passsword === '') {
            formIsValid = false;
            errors["passsword_error"] = "Please Enter Passsword";
        }
        if (checkInTime === '') {
            formIsValid = false;
            errors["checkInTime_error"] = "Please Enter Check In Time";
        }
        if (checkInUpTo === '') {
            formIsValid = false;
            errors["checkInUpTo_error"] = "Please Enter Check In Upto";
        }
        if (checkOutTime === '') {
            formIsValid = false;
            errors["checkOutTime_error"] = "Please Enter Check Out Time";
        }
        if (checkOutUpTo === '') {
            formIsValid = false;
            errors["checkOutUpTo_error"] = "Please Enter Check Out Upto";
        }
        if (capacity === '') {
            formIsValid = false;
            errors["capacity_error"] = "Please Enter Capacity";
        }
        if (maxGuest === '') {
            formIsValid = false;
            errors["maxGuest_error"] = "Please Enter Max Guest";
        }
        if (lockInPeriod === '') {
            formIsValid = false;
            errors["lockInPeriod_error"] = "Please Enter Lock In Period";
        }
        if (noticePeriod === '') {
            formIsValid = false;
            errors["noticePeriod_error"] = "Please Enter Notice Period";
        }
        if (deposit === '') {
            formIsValid = false;
            errors["deposit_error"] = "Please Enter Deposit";
        }
        if (propertyType.length > 0) {
            formIsValid = false;
            errors["propertyType_error"] = "Please Enter Property Type";
        }
        if (propertyConfig === '') {
            formIsValid = false;
            errors["propertyConfig_error"] = "Please Enter Property Config";
        }
        if (propertyConfigDetails === '') {
            formIsValid = false;
            errors["propertyConfigDetails_error"] = "Please Enter Property Config Details";
        }
        if (guestAccess === '') {
            formIsValid = false;
            errors["guestAccess_error"] = "Please Enter Guest Access";
        }
        if (howToReach === '') {
            formIsValid = false;
            errors["howToReach_error"] = "Please Enter How To Reach";
        }

        setErrors(errors)
        return formIsValid;
    }

    const handleSaveProperty=async (id) =>{
        const valid = handleValidation()
        if(valid){
            try {
                const options = {
                    "id": id,
                    "name": flatName,
                    "alias": "TZ",
                    "description": propertyDis,
                    "subPropertyCode": flatNo,
                    "subPropertyCodeName": flatCodeName,
                    "subPropertyNumber": "string",
                    "units": unit,
                    "sharing": sharing,
                    "contactPersonName": contactPerson,
                    "contactPersonMobileNumber": conatctPersonMobile,
                    "managerName": managerName,
                    "managerMobileNumber": managerMobile,
                    "hostName": hostName,
                    "hostMobileNumber": hostMobile,
                    "hostEmail": hostEmail,
                    "dailyPrice": dailyPrice,
                    "weeklyPrice": weeklyPrice,
                    "monthlyPrice": monthlyPrice,
                    "extraBedingPrice": extraBedPrice,
                    "longitude": null,
                    "latitude": null,
                    "display": display,
                    "avgRating": null,
                    "reviewCount": null,
                    "wifi": wifi,
                    "password": passsword,
                    "checkInTime": checkInTime,
                    "checkInUpTo": null,
                    "checkOutTime": null,
                    "checkOutUpTo": null,
                    "capacity": null,
                    "maxGuest": null,
                    "lockInPeriod": null,
                    "noticePeriod": null,
                    "deposit": null,
                    "subPropertyTypeId": propertyType.value,
                    "subPropertyTypeName": "House",
                    "subPropertyConfig": null,
                    "subPropertyConfigDetails": null,
                    "status": 0,
                    "guestAccess": null,
                    "otherThingToNote": null,
                    "howToReach": null,
                    "isActive": false,
                    "propertyId": 48,
                    "tenantId": 1,
                    "propertyName": "Taz Hotel"
                    }
          
                const response = await postUser('api/SubProperty/save', options)
                if(response){
                   setData(response.data.collections)
                   setPagingModel(response.data.pagingModel)
                  
                   fetchPropertyList()
                   if(id === null){
                    setModal(!modal)
                   } else {
                    setEditModal(!editModal)
                   }
                  
                }
              } catch (error) {
                console.error('Error fetching user:', error);
              }
        }
        
    }

    const handleCloseModal=()=>{
        setModal(!modal)
    }
    const handleOpenModal=()=>{
        setModal(!modal)
        setFlatName('')
    setFlatCodeName('')
    setFlatNo('')
    setUnit('')
    setSharing('')
    setDisplay('')
    setContactPerson('')
    setConatctPersonMobile('')
    setManagerName('')
    setManagerMobile('')
    setHostName('')
    setHostMobile('')
    setHostEmail('')
    setDailyPrice('')
    setWeeklyPrice('')
    setMonthlyPrice('')
    setExtraBedPrice('')
    setPropertyDis('')
    setWifi('')
    setPasssword('')
    setCheckInTime('')
    setCheckInUpTo('')
    setCheckOutTime('')
    setCheckOutUpTo('')
    setCapacity('')
    setMaxGuest('')
    setLockInPeriod('')
    setNoticePeriod('')
    setDeposit('')
    setPropertyType('')
    setPropertyConfig('')
    setPropertyConfigDetails('')
    setGuestAccess('')
    setOtherThingToNote('')
    setHowToReach('')
    }
    const handleCloseEditModal=()=>{
        setEditModal(!editModal)
    }
    const handleEditModal=async (data)=>{
        setEditModal(!editModal)
        setErrors({})
        const response = await getUser(`api/SubProperty/${data.id}`)
        if(response){
            const rowData = response.data

            const pType = propertyTypeList.filter(x => x.value === rowData.subPropertyTypeId)
            setSelectedRowID(data)
            setFlatName(data.name)
            setFlatCodeName( data.subPropertyCodeName)
            setFlatNo(data.subPropertyCode)
            setUnit(data.units)
            setSharing(data.sharing)
            setDisplay(data.display)
            setContactPerson(data.contactPersonName)
            setConatctPersonMobile(data.contactPersonMobileNumber)
            setManagerName(data.managerName)
            setManagerMobile(data.managerMobileNumber)
            setHostName(data.hostName)
            setHostMobile(data.hostMobileNumber)
            setHostEmail(data.hostEmail)
            setDailyPrice(data.dailyPrice)
            setWeeklyPrice(data.weeklyPrice)
            setMonthlyPrice(data.monthlyPrice)
            setExtraBedPrice(data.extraBedingPrice)
            setPropertyDis(data.description)
            setWifi(data.wifi)
            setPasssword(data.password)
            setCheckInTime(data.checkInTime)
            setCheckInUpTo(data.checkInUpTo)
            setCheckOutTime(data.checkOutTime)
            setCheckOutUpTo(data.checkOutUpTo)
            setCapacity(data.capacity)
            setMaxGuest(data.maxGuest)
            setLockInPeriod(data.lockInPeriod)
            setNoticePeriod(data.noticePeriod)
            setDeposit(data.deposit)
            setPropertyType(pType[0])
            setPropertyConfig(data.subPropertyConfig)
            setPropertyConfigDetails(data.subPropertyConfigDetails)
            setGuestAccess(data.guestAccess)
            setOtherThingToNote(data.otherThingToNote)
            setHowToReach(data.howToReach)
        }
    }

    const handleCloseSlideBox=()=>{
        setIsSlideModal(! isSlideModal)
    }

    const handleOpenSlideBox=()=>{
        setIsSlideModal(! isSlideModal)
    }

    const handleMore=(id, btnopenid, btncloseid)=>{
        document.getElementById(id).classList.remove('d-none')
        document.getElementById(id).classList.add('d-block')
        document.getElementById(btnopenid).classList.remove('d-block')
        document.getElementById(btnopenid).classList.add('d-none')
        document.getElementById(btncloseid).classList.add('d-block')
        document.getElementById(btncloseid).classList.remove('d-none')
    }

    const handleMoreClose=(id, btnopenid, btncloseid)=>{
        document.getElementById(id).classList.remove('d-block')
        document.getElementById(id).classList.add('d-none')
        document.getElementById(btnopenid).classList.remove('d-none')
        document.getElementById(btnopenid).classList.add('d-block')
        document.getElementById(btncloseid).classList.add('d-none')
        document.getElementById(btncloseid).classList.remove('d-block')
    }

    const handleImageModal=()=>{
        setImageModal(!imageModal)
    }
    const handleCloseImageModal=()=>{
        setImageModal(!imageModal)
    }


    const handleInfoBox=(rowData)=>{
        setInfoModal(!infoModal)
        setSelectedRowID(rowData)
    }
    const handleInfoBoxClose=()=>{
        setInfoModal(!infoModal)
    }

    const handleDeleteRow=()=>{
        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to delete this row?",
            icon: "warning",
            dangerMode: true,
            buttons: ["Cancel", "Ok"]
          })
          .then(willDelete => {
            if (willDelete) {
              swal("Deleted!", "Row has been deleted!", "success");
            }
          }); 
    }
    const fetchPropertyList = async () => {
        try {
            const options = {
                "pageSize": 10,
                "currentPage": 1,
                "name": null,
                "subPropertyCode": null,
                "subPropertyCodeName": null,
                "propertyId": props.location.state.id,
                "tenantId": null,
                "propertyTypeId": null
              }
      
            const response = await postUser('api/SubProperty/list', options)
            if(response){
                console.log(response, 'fbjkndfjk');
               setData(response.data.collections)
               setPagingModel(response.data.pagingModel)
            }
          } catch (error) {
            console.error('Error fetching user:', error);
          }
       
      }

      const fetchPropertyTypeData = async () => {
        try {
            const response = await getUser(`api/Master/property-types`)
                if(response){
                   let newData = []
                    response.data.forEach((data, i)=>{
                        newData.push({
                            label : data.name,
                            value : data.id
                        })
                    })
                    setPropertyTypeList(newData)
                }
          } catch (error) {
            console.error('Error fetching user:', error);
          }
       
      }

      const settingModel=(data)=>{
        setSettingModal(!settingModal)
        setSettingType('')
        setSelectedRowID(data)
      }
      const handleSettingBoxClose=()=>{
        setSettingModal(!settingModal)
      }

     

      useEffect(()=>{
        fetchPropertyList()
        fetchPropertyTypeData()
      }, [])

    const columns=[
        { title: '#', field: 'sno', headerStyle: { borderLeft: '0px', paddingLeft: 20, width: 50 } },
        { title: 'Flat Name', field: 'name' },
        { title: 'Flat No.', field: 'flatNo' },
        { title: 'Code Name', field: 'subPropertyCodeName' },
        { title: 'Status', field: 'status', render: rowData => <><i class="bi bi-circle-fill c-pointer text-success"></i></> },
        { title: 'Visible In Website', field: 'visibleOnSite' , render: rowData => <><i class="bi bi-circle-fill c-pointer text-success"></i></>},
        { title: '---', field: 'setting', render: rowData=><span className='c-pointer' onClick={()=>settingModel(rowData)} title='setting'><i class="bi bi-gear-fill text-primary"></i></span> },
        {
            title: 'Action', field: 'action', render: rowData => <>
                <span className='fw-bold d-block c-pointer' id={`${'morebuttonopen' + rowData.sno}`} onClick={() => handleMore('morepannel' + rowData.sno, 'morebuttonopen' + rowData.sno, 'morebuttonclose' + rowData.sno)}><MoreHorizIcon /></span>
                <span className='fw-bold d-none' id={`${'morebuttonclose' + rowData.sno}`} onClick={() => handleMoreClose('morepannel' + rowData.sno, 'morebuttonopen' + rowData.sno, 'morebuttonclose' + rowData.sno,)}><MoreHorizIcon /></span>
                <div className='d-none morepannel' id={`${'morepannel' + rowData.sno}`}>
                    <span className='d-block text-muted c-pointer' onClick={()=>handleEditModal(rowData)} title='Edit'><i class="bi bi-pencil-square text-primary fs-small"></i> <small>Edit</small></span>
                    <span className='d-block mt-1 text-muted c-pointer' onClick={()=>handleInfoBox(rowData)} title='Info'><i class="bi bi-info-square text-dark  "></i> <small>Info</small></span>
                    <span className='d-block mt-1 text-muted c-pointer' onClick={handleImageModal} title='Info'><i class="bi bi-image text-dark  "></i> <small>Image</small></span>
                    <span className='d-block mt-1 text-muted c-pointer' onClick={handleDeleteRow} title='Remove'><i class="bi bi-trash text-danger fs-small"></i> <small>Remove</small></span>
                </div>
            </>
        },
    ]
    return (
        <>
            <PageTitle title={'Sub Properties'} />
            <div className='card'>
                <div className='card-body mt-4'>
                    <span className='table-add-icon c-pointer' title='Add' onClick={handleOpenModal}><i class="bi bi-house-add"></i></span>
                    <span className='table-filter-icon c-pointer' title='Filter' onClick={handleOpenSlideBox}><i class="bi bi-funnel"></i></span>

                    {/* {
                        pagingModel && pagingModel.currentPage >0 ?
                        <CommonMaterialTable data={data} columns={columns} totalCount={pagingModel.totalItems}  rowsPerPage={pagingModel.pageSize}/> : null
                    } */}

<CommonMaterialTable data={data} columns={columns} totalCount={10}  rowsPerPage={2}/>
                    
                </div>
                
            </div>

            {
            isSlideModal ?
            <div className='slider-box'>
            <div className='slider-box-title'>
            <span className='text-primary'><b>Filter</b></span>
                <span className='text-danger fw-bold c-pointer float-end' onClick={handleCloseSlideBox}>X</span>
            </div>
            <div className='slider-box-body'>
                <Label className='text-muted fw-bold'>Name</Label>
                <Input type='text' value='' name='' onChange={handleChange} />
                <Label className='mt-3 text-muted fw-bold'>Location</Label>
                <Input type='text' value='' name='' onChange={handleChange} />
                <Label className='mt-3 text-muted fw-bold'>Status</Label>
                <Input type='select' value='' name='' onChange={handleChange} >
                    <option value=''></option>
                    <option value='Available'>Available</option>
                    <option value='Booked'>Booked</option>
                    <option value='Block'>Block</option>
                </Input>
            </div>
            <div className='slider-box-footer text-center mt-4'>
                <Button color='primary' block>Filter</Button>
            </div>
        </div> : null
        }

        <Modal isOpen={modal} size='xl'>
            <ModalHeader data-bs-theme="dark" toggle={handleCloseModal}>Add Sub Property</ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm={12}>
                        <Label>Flat Name</Label>
                        <Input type='text' name='flatName' value={flatName} onChange={handleChange} />
                        <span className='text-danger mb-2 d-block'>{errors['flatName_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Flat Code Name</Label>
                        <Input type='text' name='flatCodeName' value={flatCodeName} onChange={handleChange} />
                        <span className='text-danger mb-2 d-block'>{errors['flatCodeName_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Flat No.</Label>
                        <Input type='text' name='flatNo'  value={flatNo} onChange={handleChange} />
                        <span className='text-danger mb-2 d-block'>{errors['flatNo_error']}</span>
                    </Col>
                    <Col sm={4}>
                        <Label>Unit</Label>
                        <Input type='text' name='unit' value={unit} onChange={handleChange} />
                        <span className='text-danger mb-2 d-block'>{errors['unit_error']}</span>
                    </Col>
                    <Col sm={4}>
                        <Label>Sharing</Label>
                        <Input type='select' name='sharing' value={sharing} onChange={handleChange}>
                            <option value=''>Select</option>
                            <option value='0'>0</option>
                            <option value='1'>1</option>
                            </Input>
                            <span className='text-danger mb-2 d-block'>{errors['sharing_error']}</span>
                    </Col>
                    
                    <Col sm={4}>
                        <Label>Display</Label>
                        <Input type='select' name='display' value={display} onChange={handleChange}>
                            <option value=''>Select</option>
                            <option value='0'>0</option>
                            <option value='1'>1</option>
                            </Input>
                            <span className='text-danger mb-2 d-block'>{errors['display_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Contact Person</Label>
                        <Input type='text' name='contactPerson' value={contactPerson} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['contactPerson_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Contact Person Mobile</Label>
                        <Input type='text' name='conatctPersonMobile' value={conatctPersonMobile} onChange={handleChange} />
                        <span className='text-danger mb-2 d-block'>{errors['conatctPersonMobile_error']}</span>
                    </Col>
                    
                    <Col sm={3}>
                        <Label>Manager Name</Label>
                        <Input type='text' name='managerName' value={managerName} onChange={handleChange} />
                        <span className='text-danger mb-2 d-block'>{errors['managerName_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Manager Mobile</Label>
                        <Input type='number' name='managerMobile' value={managerMobile} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['managerMobile_error']}</span>
                    </Col>
                    <Col sm={4}>
                        <Label>Host Name</Label>
                        <Input type='text' name='hostName' value={hostName} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['hostName_error']}</span>
                    </Col>
                    <Col sm={4}>
                        <Label>Host Mobile</Label>
                        <Input type='number' name='hostMobile' value={hostMobile} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['hostMobile_error']}</span>
                    </Col>
                    <Col sm={4}>
                        <Label>Host Email</Label>
                        <Input type='text' name='hostEmail' value={hostEmail} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['hostEmail_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Daily Price</Label>
                        <Input type='number' name='dailyPrice' value={dailyPrice} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['dailyPrice_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Weekly Price</Label>
                        <Input type='number' name='weeklyPrice' value={weeklyPrice} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['weeklyPrice_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Monthly Price</Label>
                        <Input type='number' name='monthlyPrice' value={monthlyPrice} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['monthlyPrice_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Extra Bedding Price</Label>
                        <Input type='number' name='extraBedPrice' value={extraBedPrice} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['extraBedPrice_error']}</span>
                    </Col>
                    <Col sm={12}>
                        <Label>Property Description</Label>
                        <Input type='textarea' name='propertyDis' value={propertyDis} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['propertyDis_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Wifi</Label>
                        <Input type='text' name='wifi' value={wifi} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['wifi_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Password</Label>
                        <Input type='text' name='passsword' value={passsword} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['passsword_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Check In Time</Label>
                        <Input type='time' name='checkInTime' value={checkInTime} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['checkInTime_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Check In Upto</Label>
                        <Input type='time' name='checkInUpTo' value={checkInUpTo} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['checkInUpTo_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Check Out Time</Label>
                        <Input type='time' name='checkOutTime' value={checkOutTime} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['checkOutTime_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Check Out Upto</Label>
                        <Input type='time' name='checkOutUpTo' value={checkOutUpTo} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['checkOutUpTo_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Capacity</Label>
                        <Input type='text' name='capacity' value={capacity} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['capacity_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Max Guest</Label>
                        <Input type='text' name='maxGuest' value={maxGuest} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['maxGuest_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Lock In Period</Label>
                        <Input type='text' name='lockInPeriod' value={lockInPeriod} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['lockInPeriod_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Notice Period</Label>
                        <Input type='text' name='noticePeriod' value={noticePeriod} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['noticePeriod_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Deposit</Label>
                        <Input type='text' name='deposit' value={deposit} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['deposit_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Property Type</Label>
                        <Select
                            value={propertyType}
                            onChange={handleChangeType}
                            label="Single select"
                            options={propertyTypeList}
                            />
                        <span className='text-danger mb-2 d-block'>{errors['propertyType_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Property Config</Label>
                        <Input type='textarea' name='propertyConfig' value={propertyConfig} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['propertyConfig_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Property Config Details</Label>
                        <Input type='textarea' name='propertyConfigDetails' value={propertyConfigDetails} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['propertyConfigDetails_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Guest Access</Label>
                        <Input type='textarea' name='guestAccess' value={guestAccess} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['guestAccess_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Other Things To Note</Label>
                        <Input type='textarea' name='otherThingToNote' value={otherThingToNote} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['otherThingToNote_error']}</span>
                    </Col>
                    <Col sm={12}>
                        <Label>How To Reach</Label>
                        <Input type='textarea' name='howToReach' value={howToReach} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['howToReach_error']}</span>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color='danger' className='mr-1'>Cancel</Button>
                <Button color='primary' onClick={()=>handleSaveProperty(null)}>Save</Button>
            </ModalFooter>
        </Modal>
        <Modal isOpen={editModal} size='xl'>
            <ModalHeader data-bs-theme="dark" toggle={handleCloseEditModal}>Edit Sub Property</ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm={12}>
                        <Label>Flat Name</Label>
                        <Input type='text' name='flatName' value={flatName} onChange={handleChange} />
                        <span className='text-danger mb-2 d-block'>{errors['flatName_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Flat Code Name</Label>
                        <Input type='text' name='flatCodeName' value={flatCodeName} onChange={handleChange} />
                        <span className='text-danger mb-2 d-block'>{errors['flatCodeName_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Flat No.</Label>
                        <Input type='text' name='flatNo'  value={flatNo} onChange={handleChange} />
                        <span className='text-danger mb-2 d-block'>{errors['flatNo_error']}</span>
                    </Col>
                    <Col sm={4}>
                        <Label>Unit</Label>
                        <Input type='text' name='unit' value={unit} onChange={handleChange} />
                        <span className='text-danger mb-2 d-block'>{errors['unit_error']}</span>
                    </Col>
                    <Col sm={4}>
                        <Label>Sharing</Label>
                        <Input type='select' name='sharing' value={sharing} onChange={handleChange}>
                            <option value=''>Select</option>
                            <option value='0'>0</option>
                            <option value='1'>1</option>
                            </Input>
                            <span className='text-danger mb-2 d-block'>{errors['sharing_error']}</span>
                    </Col>
                    
                    <Col sm={4}>
                        <Label>Display</Label>
                        <Input type='select' name='display' value={display} onChange={handleChange}>
                            <option value=''>Select</option>
                            <option value='0'>0</option>
                            <option value='1'>1</option>
                            </Input>
                            <span className='text-danger mb-2 d-block'>{errors['display_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Contact Person</Label>
                        <Input type='text' name='contactPerson' value={contactPerson} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['contactPerson_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Contact Person Mobile</Label>
                        <Input type='text' name='conatctPersonMobile' value={conatctPersonMobile} onChange={handleChange} />
                        <span className='text-danger mb-2 d-block'>{errors['conatctPersonMobile_error']}</span>
                    </Col>
                    
                    <Col sm={3}>
                        <Label>Manager Name</Label>
                        <Input type='text' name='managerName' value={managerName} onChange={handleChange} />
                        <span className='text-danger mb-2 d-block'>{errors['managerName_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Manager Mobile</Label>
                        <Input type='number' name='managerMobile' value={managerMobile} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['managerMobile_error']}</span>
                    </Col>
                    <Col sm={4}>
                        <Label>Host Name</Label>
                        <Input type='text' name='hostName' value={hostName} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['hostName_error']}</span>
                    </Col>
                    <Col sm={4}>
                        <Label>Host Mobile</Label>
                        <Input type='number' name='hostMobile' value={hostMobile} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['hostMobile_error']}</span>
                    </Col>
                    <Col sm={4}>
                        <Label>Host Email</Label>
                        <Input type='text' name='hostEmail' value={hostEmail} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['hostEmail_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Daily Price</Label>
                        <Input type='number' name='dailyPrice' value={dailyPrice} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['dailyPrice_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Weekly Price</Label>
                        <Input type='number' name='weeklyPrice' value={weeklyPrice} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['weeklyPrice_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Monthly Price</Label>
                        <Input type='number' name='monthlyPrice' value={monthlyPrice} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['monthlyPrice_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Extra Bedding Price</Label>
                        <Input type='number' name='extraBedPrice' value={extraBedPrice} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['extraBedPrice_error']}</span>
                    </Col>
                    <Col sm={12}>
                        <Label>Property Description</Label>
                        <Input type='textarea' name='propertyDis' value={propertyDis} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['propertyDis_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Wifi</Label>
                        <Input type='text' name='wifi' value={wifi} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['wifi_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Password</Label>
                        <Input type='text' name='passsword' value={passsword} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['passsword_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Check In Time</Label>
                        <Input type='time' name='checkInTime' value={checkInTime} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['checkInTime_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Check In Upto</Label>
                        <Input type='time' name='checkInUpTo' value={checkInUpTo} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['checkInUpTo_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Check Out Time</Label>
                        <Input type='time' name='checkOutTime' value={checkOutTime} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['checkOutTime_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Check Out Upto</Label>
                        <Input type='time' name='checkOutUpTo' value={checkOutUpTo} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['checkOutUpTo_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Capacity</Label>
                        <Input type='text' name='capacity' value={capacity} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['capacity_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Max Guest</Label>
                        <Input type='text' name='maxGuest' value={maxGuest} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['maxGuest_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Lock In Period</Label>
                        <Input type='text' name='lockInPeriod' value={lockInPeriod} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['lockInPeriod_error']}</span>
                    </Col>
                    <Col sm={3}>
                        <Label>Notice Period</Label>
                        <Input type='text' name='noticePeriod' value={noticePeriod} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['noticePeriod_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Deposit</Label>
                        <Input type='text' name='deposit' value={deposit} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['deposit_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Property Type</Label>
                        <Select
                            value={propertyType}
                            onChange={handleChangeType}
                            label="Single select"
                            options={propertyTypeList}
                            />
                        <span className='text-danger mb-2 d-block'>{errors['propertyType_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Property Config</Label>
                        <Input type='textarea' name='propertyConfig' value={propertyConfig} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['propertyConfig_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Property Config Details</Label>
                        <Input type='textarea' name='propertyConfigDetails' value={propertyConfigDetails} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['propertyConfigDetails_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Guest Access</Label>
                        <Input type='textarea' name='guestAccess' value={guestAccess} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['guestAccess_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Other Things To Note</Label>
                        <Input type='textarea' name='otherThingToNote' value={otherThingToNote} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['otherThingToNote_error']}</span>
                    </Col>
                    <Col sm={12}>
                        <Label>How To Reach</Label>
                        <Input type='textarea' name='howToReach' value={howToReach} onChange={handleChange}/>
                        <span className='text-danger mb-2 d-block'>{errors['howToReach_error']}</span>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color='danger' className='mr-1'>Cancel</Button>
                <Button color='primary' onClick={()=>handleSaveProperty(selectedRowID.id)}>Save</Button>
            </ModalFooter>
        </Modal>

        <Modal isOpen={imageModal} size='lg'>
            <ModalHeader data-bs-theme="dark" toggle={handleCloseImageModal}>{props.location.state.name}</ModalHeader>
            <ModalBody>
            </ModalBody>
            <ModalFooter>
                <Button color='danger' className='mr-1'>Cancel</Button>
                <Button color='primary'>Save</Button>
            </ModalFooter>
        </Modal>

        <Modal isOpen={infoModal} size='lg'>
            <ModalHeader data-bs-theme="dark" toggle={handleInfoBoxClose}>{selectedRowID.name} <small> {selectedRowID.subPropertyCodeName} ({selectedRowID.subPropertyCode})</small></ModalHeader>
            <ModalBody className='text-center'>
                <Row>
                    {/* <Col sm={6}>
                        <p  className='text-start'>Hotel Snow Crest Inn <br/>
                            HOTEL SNOW CREST INN - HOTEL   </p>
                    </Col>
                    <Col sm={6}>
                        <small className='text-end' >snowcrest101 ( snowcrest101 )</small>
                    </Col> */}
                    <Col sm={12}>
                        <small className='text-start d-block mb-2'>2 guests.1 bedroom.1 bed.1 private bathroom</small>
                    </Col>
                    <Col sm={6} className='border-end'>
                        <small>Contact Preson</small>
                        <p>{selectedRowID.contactPersonName}</p>
                    </Col>
                    <Col sm={6}>
                        <small>Contact Preson Mobile</small>
                        <p>{selectedRowID.contactPersonMobileNumber}</p>
                    </Col>
                    <Col sm={12}><p className='mt-3 border-bottom'></p></Col>
                    <Col sm={12}><small className='text-start d-block'>Price</small></Col>
                    <Col sm={3} className='border-end'>
                        <small>Daily</small>
                        <p>{selectedRowID.dailyPrice}</p>
                    </Col>
                    <Col sm={3} className='border-end'>
                        <small>Monthly</small>
                        <p>{selectedRowID.monthlyPrice}</p>
                    </Col>
                    <Col sm={3} className='border-end'>
                        <small>Weekly</small>
                        <p>{selectedRowID.weeklyPrice}</p>
                    </Col>
                    <Col sm={3}>
                        <small>Extra Biding</small>
                        <p>{selectedRowID.extraBedingPrice || 'NA'}</p>
                    </Col>
                    <Col sm={12}><p className='mt-3 border-bottom'></p></Col>
                    <Col sm={12}><small className='text-start d-block'>Time</small></Col>
                    <Col sm={3}  className='border-end'>
                        <small>Check in</small>
                        <p>{selectedRowID.checkInTime  || 'NA'}</p>
                    </Col>
                    <Col sm={3} className='border-end'>
                        <small>Check in Upto</small>
                        <p>{selectedRowID.checkInUpTo || 'NA'}</p>
                    </Col>
                    <Col sm={3} className='border-end'>
                        <small>Check out </small>
                        <p>{selectedRowID.checkOutTime || 'NA'}</p>
                    </Col>
                    <Col sm={3}>
                        <small>Check out Upto</small>
                        <p>{selectedRowID.checkOutUpTo || 'NA'}</p>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color='danger' className='mr-1' onClick={handleInfoBoxClose}>Cancel</Button>
            </ModalFooter>
        </Modal>
        <Modal isOpen={settingModal} size='lg'>
            <ModalHeader data-bs-theme="dark" toggle={handleSettingBoxClose}>Setting</ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm={12}>
                        <select className='form-select' value={settingType} name='settingType' onChange={handleChange}>
                            <option value=''>Select</option>
                            <option value='policy'>Policy</option>
                            <option value='amenities'>Amenities</option>
                            <option value='activity'>Activity</option>
                            <option value='sleeping arrengment'>Sleeping Arrengment</option>
                            <option value='kitchen'>Kitchen</option>
                            <option value='broadband'>Broadband</option>
                        </select>
                    </Col>
                    <Col sm={12}>
                        {settingType}
                    </Col>
                    
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color='danger' className='mr-1' onClick={handleSettingBoxClose}>Cancel</Button>
            </ModalFooter>
        </Modal>
        </>
    )
}

export default SubPropertyList