import Login from "./pages/login";
import Panel from "./pages/main";
import NotFound from "./pages/notFound";
import PropertyList from "./pages/properties/propertyList";
import SubPropertyList from "./pages/properties/subPropertyList";
import PropertyReview from "./pages/propertyReview";
import UserRegister from "./pages/userRegister";

const routes = [
  {
    exact: true,
    path: "/",
    component: Panel,
    private: true
  },
  {
    path: "/user-register",
    component: UserRegister,
    private: true
  },
  {
    path: "/property-list",
    component: PropertyList,
    private: true
  },
  {
    path: "/sub-property-list",
    component: SubPropertyList,
    private: true
  },
  {
    path: "/review",
    component: PropertyReview,
    private: true
  },
  {
    path: "/Login",
    component: Login
  },
  {
    path: "*",
    component: NotFound
  }
];

export default routes;
