import React, { useState } from "react";
import PageTitle from "./pageTitle";
import {postUser} from '../hooks/Interceptor'
import swal from 'sweetalert'

  const types = ["success", "info", "warning", "error"];

const UserRegister=()=>{

    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [lastName, setLastName] = useState('')
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [errors, setErrors] = useState({})

    const handleChange=(e)=>{
        if(e.target.name === 'email'){
            setEmail(e.target.value)
        }
        if(e.target.name === 'firstName'){
            setFirstName(e.target.value)
        }
        if(e.target.name === 'middleName'){
            setMiddleName(e.target.value)
        }
        if(e.target.name === 'lastName'){
            setLastName(e.target.value)
        }
        if(e.target.name === 'userName'){
            setUserName(e.target.value)
        }
        if(e.target.name === 'password'){
            setPassword(e.target.value)
        }
        if(e.target.name === 'confirmPassword'){
            setConfirmPassword(e.target.value)
        }
        if(e.target.name === 'phoneNumber'){
            setPhoneNumber(e.target.value)
        }
    }

    const validation=()=>{
        let errors = {};
    let formIsValid = true;

    var regex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;

    if( email === ''){
      formIsValid = false;
      errors["email_error"] = "Please Enter Email ID";
    } else if (!regex.test(email)) {
        formIsValid = false;
        errors["email_error"] = "Please Enter Valid Email ID";
    }
    if(firstName === ''){
      formIsValid = false;
      errors["fname_error"] = "Please Enter First Name";
    }
    if(lastName === ''){
      formIsValid = false;
      errors["lname_error"] = "Please Enter Last Name";
    }
    if( userName === ''){
      formIsValid = false;
      errors["username_error"] = "Please Enter User Name";
    }
    if(password === ''){
      formIsValid = false;
      errors["pass_error"] = "Please Enter Password";
    }
    if( confirmPassword === ''){
      formIsValid = false;
      errors["confir_error"] = "Please Enter Confirm Password";
    } else if(confirmPassword !== password) {
        formIsValid = false;
        errors["confir_error"] = "Password not Matched";
    }
    if(phoneNumber === ''){
      formIsValid = false;
      errors["phonenumber_error"] = "Please Enter Phone Number";
    }

    setErrors(errors)
    return formIsValid;
    }

    const handleSubmit = async () => {
        
        
        const isValid = validation()
        if(isValid){
            try {
                const user = {
                //   "email": "anik55@yopmail.com",
                //   "firstName": "Anikccccccccccc",
                //   "middleName": "",
                //   "lastName": "Kumar",
                //   "userName": "anik55@yopmail.com",
                //   "password": "Pass@123",
                //   "confirmPassword": "Pass@123",
                //   "PhoneNumber": "9876543210"

                    "email": email,
                  "firstName": firstName,
                  "middleName": middleName,
                  "lastName": lastName,
                  "userName": userName,
                  "password": password,
                  "confirmPassword": confirmPassword,
                  "PhoneNumber": phoneNumber
                };
          
                const response = await postUser('api/Account/Register', user)
                if(response){
                    swal('', 'User Register Added Successfully', 'success')
                }
              } catch (error) {
                console.error('Error fetching user:', error);
              }
        }
       
      }

    return(
        <>
            <PageTitle title='User Register' />
            <div class="card">
            <div class="card-body">
              {/* <h5 class="card-title"></h5> */}
              <br/>
              <div class="row g-3">
                <div class="col-sm-6">
                  <label for="inputNanme4" class="form-label">Email<span className="text-danger">*</span></label>
                  <input type="text" class="form-control" value={email} name='email' onChange={handleChange}/>
                  <small className="text-danger">{errors["email_error"]}</small>
                </div>
                <div class="col-sm-6">
                  <label for="inputEmail4" class="form-label">First Name<span className="text-danger">*</span></label>
                  <input type="text" class="form-control" value={firstName} name='firstName' onChange={handleChange}/>
                  <small className="text-danger">{errors["fname_error"]}</small>
                </div>
                <div class="col-sm-6">
                  <label for="inputPassword4" class="form-label">Middle Name</label>
                  <input type="text" class="form-control" value={middleName} name='middleName' onChange={handleChange}/>
                </div>
                <div class="col-sm-6">
                  <label for="inputAddress" class="form-label">Last Name<span className="text-danger">*</span></label>
                  <input type="text" class="form-control" value={lastName} name='lastName' onChange={handleChange}/>
                  <small className="text-danger">{errors["lname_error"]}</small>
                </div>
                <div class="col-sm-6">
                  <label for="inputAddress" class="form-label">User Name<span className="text-danger">*</span></label>
                  <input type="text" class="form-control" value={userName} name='userName' onChange={handleChange}/>
                  <small className="text-danger">{errors["username_error"]}</small>
                </div>
                <div class="col-sm-6">
                  <label for="inputAddress" class="form-label">Password<span className="text-danger">*</span></label>
                  <input type="text" class="form-control" value={password} name='password' onChange={handleChange}/>
                  <small className="text-danger">{errors["pass_error"]}</small>
                </div>
                <div class="col-sm-6">
                  <label for="inputAddress" class="form-label">Confirm Password<span className="text-danger">*</span></label>
                  <input type="text" class="form-control" value={confirmPassword} name='confirmPassword' onChange={handleChange}/>
                  <small className="text-danger">{errors["confir_error"]}</small>
                </div>
                <div class="col-sm-6">
                  <label for="inputAddress" class="form-label">Phone Number<span className="text-danger">*</span></label>
                  <input type="text" class="form-control" value={phoneNumber} name='phoneNumber' onChange={handleChange}/>
                  <small className="text-danger">{errors["phonenumber_error"]}</small>
                </div>
                <div class="text-end">
                  <button onClick={handleSubmit}  class="btn btn-primary me-2">Submit</button>
                  <button  class="btn btn-secondary">Reset</button>
                </div>
              </div>

            </div>
          </div>
        </>
    )
}

export default UserRegister;