import React, { useEffect, useState } from 'react'
import MaterialTable from "material-table";
// Import Material Icons
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {
    Paper,
    Typography,
    withStyles,
    makeStyles,
    Checkbox,
    FormControlLabel,
    Tooltip,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    FormControl,
    Select

  } from "@material-ui/core";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const CommonMaterialTable=(props)=>{
    const [totalCount, setTotalCount] = useState(props.totalCount);
    const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage);
    const [paginationDDData, setPaginationDDData] = useState([]);

    const CustomPaginationComponent = (props) => {
        const { page, rowsPerPage, count, onChangePage, onChangeRowsPerPage, rowsPerPageOptions } = props;
        let from = rowsPerPage * page + 1;
        let to = rowsPerPage * (page + 1);
        if (to > count) {
            to = count;
        }
        return (
            <td className='float-right'>
                <Grid container alignItems="center" style={{ paddingTop: 8 }}>
                    <Grid item style={{padding: 0}}>
                        <FormControl>
                            {/* <InputLabel>Age</InputLabel> */}
                            <Select
                                labelId="demo-simple-select-label"
                                id="custom-pagination"
                                style={{marginRight: 10}}
                                value={rowsPerPage}
                                onChange={onChangeRowsPerPage}
                            >
                                {rowsPerPageOptions.map((x, i) => (
                                    <MenuItem value={x.value} key={i}>
                                       <Typography variant="caption" style={{ color: "black" }}>{x.label}</Typography> 
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item><Typography variant="caption" style={{ color: "black" }}>rows</Typography></Grid>
                    <Grid item>
                        <IconButton
                            disabled={page === 0}
                            onClick={(e) => onChangePage(e, 0)}
                        >
                            <FirstPage
                                fontSize="small"
                                color={page === 0 ? "disabled" : "primary"}
                            />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton disabled={page === 0} onClick={(e) => onChangePage(e, page - 1)}>
                            <ChevronLeft fontSize="small" color={page === 0 ? "disabled" : "primary"} />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" style={{ color: "black" }}>
                            {/* {from}-{to} of {count} */}
                            {from}-{to} of {totalCount}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton disabled={to >= count} onClick={(e) => onChangePage(e, page + 1)}>
                            <ChevronRight fontSize="small" color={to < count ? "primary" : "disabled"} />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton
                            disabled={to >= count}
                            onClick={(e) => onChangePage(e, count)}
                        >
                            <LastPage
                                fontSize="small"
                                color={to >= count ? "disabled" : "primary"}
                            />
                        </IconButton>
                    </Grid>
                </Grid>
            </td>
        );
    };

    const changeRowsNumber=(row)=>{
        paginationArr(row)
    }

    const paginationArr=(val)=>{
        var len = (val/10)+1
        var newArr = []

        for (var i = 1; i < len; i++) {
            newArr.push({value:i*10, label:i*10})
        }
        
        const newData = [...newArr, { value: val > 0 ? val : 1, label: 'All' }]
        setPaginationDDData(newData)
    }

    useEffect(()=>{
        paginationArr()
    }, [props])

    return (
        <MaterialTable
            title=""
            icons={tableIcons}
            columns={props.columns}
            data={props.data}
            onChangeRowsPerPage={(rows) => {
                changeRowsNumber(rows)
            }}
            options={{
                actionsColumnIndex: -1,
                sorting: true,
                exportButton: false,
                search: false,
                rowStyle: x => {
                    if (x.tableData.id % 2) {
                        return { backgroundColor: "#f2f2f2" }
                    }
                },
                headerStyle: {
                    backgroundColor: '#012970',
                    color: '#fff',
                    border: '1px solid #dee2e6 ',
                    borderRight: '0px',
                    'font-weight': 'bold',
                    padding: '10px 5px',
                },
                maxBodyHeight: 'auto',
                pageSize: rowsPerPage,
                paging: totalCount === 0 ? false : true,
                pageSizeOptions: paginationDDData

            }}
            components={{
                Pagination: (props) => {
                    return <CustomPaginationComponent {...props} />;
                }
            }}
        />
    )
}

export default CommonMaterialTable