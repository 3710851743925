import React from "react";
import { Redirect, Route } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Header from "../pages/layout/header";
import SideBar from "../pages/layout/sidebar";
function PrivateRoute(props) {
  const isLoggeIn = useAuth();
  return isLoggeIn ? 
  <>
  <Header />
  <SideBar />
  <main id="main" class="main">
  <Route {...props} /> 
  </main>
  </>
  
  : <Redirect to="/Login" />;
}

export default PrivateRoute;
