import axios from 'axios';

const token = localStorage.getItem('Token');

const authFetch = axios.create({
  baseURL: 'https://blablastay-admin-api-dev-net.azurewebsites.net/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Authorization' : `Bearer ${token}`
  },
});

authFetch.interceptors.request.use(
  (request) => {
    if (token) {
        request.headers.Authorization = `Bearer ${token}`;
      }
    console.log('request sent');
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

authFetch.interceptors.response.use(
  (response) => {
    if (token) {
      response.headers.Authorization = `Bearer ${token}`;
    }
    console.log('got response', response);
    return response;
  },
  (error) => {
    console.log(error.response);
    if (error.response.status === 404) {
      // do something
      console.log('NOT FOUND');
    }
    return Promise.reject(error);
  }
);


// API endpoints
export const getUser = (url) => {
  return authFetch.get(url);
};


export const postUser = (url, options) => {
  return authFetch.post(url, options);
};

export const deleteApi=(url) =>{
  return authFetch.delete(url);
}
  

export default authFetch;
