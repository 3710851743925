import React, { useEffect, useState } from 'react'

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PageTitle from '../pageTitle';
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents  } from 'react-leaflet';
import { Button, Input, Label, Modal, ModalBody, ModalHeader, ModalFooter, Row, Col, FormGroup } from 'reactstrap';
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch'

import icon from "./constants";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import { Link } from 'react-router-dom';
import CommonMaterialTable from '../../common/materialTable';
import swal from 'sweetalert'
import { deleteApi, getUser, postUser } from '../../hooks/Interceptor';
import SelectCC from "react-select";

// Import Material Icons
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {
    Paper,
    Typography,
    withStyles,
    makeStyles,
    Checkbox,
    FormControlLabel,
    Tooltip,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    FormControl,
    Select

  } from "@material-ui/core";
  import MaterialTable from "material-table";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


const position = [51.505, -0.09]



const PropertyList = (props) => {
    const [isSlideModal, setIsSlideModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [infoModal, setInfoModal] = useState(false)
    const [editModal, setEditModal] = useState(false)

    const [data, setData] = useState()
    const [pagingModel, setPagingModel] = useState()

    const [selectedRowID, setSelectedRowID] = useState({})

    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const [citiesList, setCitiesList] = useState([])
    const [locationList, setLocationList] = useState([])
    const [propertyTypeList, setPropertyTypeList] = useState([])

    const [values, setValues] = useState({ 
        propertyName: '', 
        propertyCode: '', 
        propertyCodeName:'',
        propertyContactPerson:'',
        propertyContactPersonMobile:'',
        propertyAddress:'',
        propertyLatitude:'',
        propertyLongitude:'',
        propertyType:[],
     });

     const [propertyCountry, setpropertyCountry] = useState([])
     const [propertyState, setPropertyState] = useState([])
     const [propertyCity, setPropertyCity] = useState([])
     const [propertyLocation, setPropertyLocation] = useState([])

    const [errors, setErrors] = useState({})

    
    const [totalCount, setTotalCount] = useState(props.totalCount);
    const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage);
    const [paginationDDData, setPaginationDDData] = useState([]);
    const [pagesize, setPagesize] = useState(1)

    const CustomPaginationComponent = (props) => {
        const { page, rowsPerPage, count, onChangePage, onChangeRowsPerPage, rowsPerPageOptions } = props;
        console.log(props, 'props');
        let from = rowsPerPage * page + 1;
        let to = rowsPerPage * (page + 1);
        if (to > count) {
            to = count;
        }
        return (
            <td className='float-right'>
                <Grid container alignItems="center" style={{ paddingTop: 8 }}>
                    <Grid item style={{padding: 0}}>
                        <FormControl>
                            {/* <InputLabel>Age</InputLabel> */}
                            <Select
                                labelId="demo-simple-select-label"
                                id="custom-pagination"
                                style={{marginRight: 10}}
                                value={rowsPerPage}
                                onChange={onChangeRowsPerPage}
                            >
                                {/* {rowsPerPageOptions.map((x, i) => ( */}
                                {paginationDDData.map((x, i) => (
                                    <MenuItem value={x.value} key={i}>
                                       <Typography variant="caption" style={{ color: "black" }}>{x.label}</Typography> 
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item><Typography variant="caption" style={{ color: "black" }}>rows</Typography></Grid>
                    <Grid item>
                        <IconButton
                            disabled={page === 0}
                            onClick={(e) => onChangePage(e, 0)}
                        >
                            <FirstPage
                                fontSize="small"
                                color={page === 0 ? "disabled" : "primary"}
                            />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton disabled={page === 0} onClick={(e) => onChangePage(e, page - 1)}>
                            <ChevronLeft fontSize="small" color={page === 0 ? "disabled" : "primary"} />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" style={{ color: "black" }}>
                            {/* {from}-{to} of {count} */}
                            {from}-{to} of {totalCount}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton disabled={to >= count} onClick={(e) => onChangePage(e, page + 1)}>
                            <ChevronRight fontSize="small" color={to < count ? "primary" : "disabled"} />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton
                            disabled={to >= count}
                            onClick={(e) => onChangePage(e, count)}
                        >
                            <LastPage
                                fontSize="small"
                                color={to >= count ? "disabled" : "primary"}
                            />
                        </IconButton>
                    </Grid>
                </Grid>
            </td>
        );
    };

    const changeRowsNumber=(row)=>{
        paginationArr(row)
        fetchPropertyList(row, Math.round(row/10))
    }

    const paginationArr=(val)=>{
        var len = (val/10)+1
        var newArr = []

        for (var i = 1; i < len; i++) {
            newArr.push({value:i*10, label:i*10})
        }
        
        const newData = [...newArr, { value: val > 10 ? val : totalCount, label: 'All' }]
        console.log(newData, 'khbjkhlj');
        setPaginationDDData(newData)
    }


    const handleChange = (name) => {
        
        return (event) => {
          setValues({ ...values, [name]: event.target.value });
        };
      };

    const handleChangeCountry=(selectedOption)=>{
        setpropertyCountry(selectedOption)
        fetchStateData(selectedOption.value)
    }
    const handleChangeState=(selectedOption)=>{
        setPropertyState(selectedOption)
        fetchCityData(selectedOption.value)
    }
    const handleChangeCity=(selectedOption)=>{
        setPropertyCity(selectedOption)
        fetchLocationData( propertyState.value,selectedOption.value)
    }
    const handleChangeLocation=(selectedOption)=>{
        setPropertyLocation(selectedOption)
    }
    const handleChangeType=(selectedOption)=>{
        setValues({ ...values, propertyType: selectedOption })
    }

    const validation = () => {
        let errors = {};
        let formIsValid = true;

        if (values.propertyName === '') {
            formIsValid = false;
            errors["propertyName_error"] = "Please Enter Property Name";
        }
        if (values.propertyCode === '') {
            formIsValid = false;
            errors["propertyCode_error"] = "Please Enter Property Code";
        }
        if (values.propertyCodeName === '') {
            formIsValid = false;
            errors["propertyCodeName_error"] = "Please Enter Property Code Name";
        }
        if (values.propertyContactPerson === '') {
            formIsValid = false;
            errors["propertyContactPerson_error"] = "Please Enter Property Contact Person";
        }
        if (values.propertyContactPersonMobile === '') {
            formIsValid = false;
            errors["propertyContactPersonMobile_error"] = "Please Enter Property Contact Person Mobile";
        }
        if (propertyCountry.length === 0) {
            formIsValid = false;
            errors["propertyCountry_error"] = "Please Enter Property Country";
        }
        if ((propertyState).length === 0) {
            formIsValid = false;
            errors["propertyState_error"] = "Please Enter Property State";
        }
        if (propertyCity.length === 0) {
            formIsValid = false;
            errors["propertyCity_error"] = "Please Enter Property City";
        }
        if (values.propertyAddress === '') {
            formIsValid = false;
            errors["propertyAddress_error"] = "Please Enter Property Address";
        }
        if (values.propertyLatitude === '') {
            formIsValid = false;
            errors["propertyLatitude_error"] = "Please Enter Property Latitude";
        }
        if (values.propertyLongitude === '') {
            formIsValid = false;
            errors["propertyLongitude_error"] = "Please Enter Property Longitude";
        }
        if (propertyLocation.length === 0) {
            formIsValid = false;
            errors["propertyLocation_error"] = "Please Enter Property Location";
        }
        if (values.propertyType.length === 0) {
            formIsValid = false;
            errors["propertyType_error"] = "Please Enter Property Type";
        }

        setErrors(errors)
        return formIsValid;
    }

    const LeafletgeoSearch=() =>{
        const map = useMap();
        useEffect(() => {
          const provider = new OpenStreetMapProvider();
      
          const searchControl = new GeoSearchControl({
            provider,
            marker: {
              icon
            }
          });
      
          map.addControl(searchControl);
          console.log(map.addControl(searchControl))
      
          return () => map.removeControl(searchControl);
        }, [props]);
      
        return null;
      }

      function LocationMarker() {
        const [position, setPosition] = useState(null)
        const map = useMapEvents({
          click() {
            
            map.locate(map.locate())
          },
          locationfound(e) {
            
            setPosition(e.latlng)
            map.flyTo(e.latlng, map.getZoom())
          },
        })

        console.log(map);
      
        return position === null ? null : (
          <Marker position={position}>
            <Popup>You are here</Popup>
          </Marker>
        )
      }


    const handleMore=(id, btnopenid, btncloseid)=>{
        document.getElementById(id).classList.remove('d-none')
        document.getElementById(id).classList.add('d-block')
        document.getElementById(btnopenid).classList.remove('d-block')
        document.getElementById(btnopenid).classList.add('d-none')
        document.getElementById(btncloseid).classList.add('d-block')
        document.getElementById(btncloseid).classList.remove('d-none')
    }

    const handleMoreClose=(id, btnopenid, btncloseid)=>{
        document.getElementById(id).classList.remove('d-block')
        document.getElementById(id).classList.add('d-none')
        document.getElementById(btnopenid).classList.remove('d-none')
        document.getElementById(btnopenid).classList.add('d-block')
        document.getElementById(btncloseid).classList.add('d-none')
        document.getElementById(btncloseid).classList.remove('d-block')
    }

    const handleCloseSlideBox=()=>{
        setIsSlideModal(! isSlideModal)
    }

    const handleOpenSlideBox=()=>{
        setIsSlideModal(! isSlideModal)
    }

    const handleCloseModal=()=>{
        setModal(!modal)
    }
    const handleOpenModal=()=>{
        setModal(!modal)
        setValues({ 
            propertyName: '', 
            propertyCode: '', 
            propertyCodeName:'',
            propertyContactPerson:'',
            propertyContactPersonMobile:'',
            propertyAddress:'',
            propertyLatitude:'',
            propertyLongitude:'',
            propertyType:[],
         });
         setPropertyCity([])
         setPropertyLocation([])
         setPropertyState([])
         setpropertyCountry([])
    }
    const handleCloseEditModal=()=>{
        setEditModal(!editModal)
        document.getElementById('morebuttonopen'+selectedRowID.id).classList.remove('d-block')
               document.getElementById('morebuttonopen'+selectedRowID.id).classList.add('d-none')
    }
    const handleOpenEditModal=async(data)=>{
        setErrors({})
        try {
            const response = await getUser(`api/Property/${data.id}`)
            if(response){
                const rowData = response.data
    
                const pType = propertyTypeList.filter(x => x.value === rowData.propertyTypeId)
                setSelectedRowID(rowData)
                fetchStateData(101, rowData)
                fetchCityData(rowData.stateId, rowData)
                fetchLocationData(rowData.stateId, rowData.cityId, rowData)
                setValues(
                    { 
                        propertyName: rowData.name, 
                        propertyCode: rowData.propertyCode, 
                        propertyCodeName:rowData.propertyCodeName,
                        propertyContactPerson:rowData.contactPersonName,
                        propertyContactPersonMobile:rowData.contactPersonPhoneNumber,
                        // propertyCountry:rowData.pCountry,
                        propertyCountry:{label: 'India', value:101},
                        propertyAddress:rowData.addressLine,
                        propertyLatitude:rowData.latitude,
                        propertyLongitude:rowData.longitude,
                        propertyType:pType[0],
                     }
                )
                
                 setpropertyCountry({label: 'India', value:101})
                setEditModal(!editModal)
            }

           
        } catch (error) {
            console.error('Error fetching user:', error);
          }
        
       
        
    }

    const handleClick = (e) => {
        const { lat, lng } = e.latlng;
        console.log(lat, lng);
    }

    const [deleteFlag, setDeleteFlag] = useState(false)

    const hjbhbj=async(id)=>{
        setDeleteFlag(false)
        if(id > 0){
            swal({
                title: "Are you sure?",
                text: "Are you sure that you want to delete this row?",
                icon: "warning",
                dangerMode: true,
                buttons: ["Cancel", "Ok"]
              })
              .then(willDelete => {
                if (willDelete) {
                  swal("Deleted!", "Row has been deleted!", "success");
                  setDeleteFlag(true)
                }
              });
        }
       
    }

    const handleDeleteRow=async(id)=>{
           
         if(window.confirm("Are you sure you want to delete?")){
            await deleteApi(`api/Property/delete-property?id=${id}`)
            fetchPropertyList(10, 1)
         }
    }

    const handleInfoBox=(rowData)=>{
        setInfoModal(!infoModal)
        setSelectedRowID(rowData)
       
    }
    const handleInfoBoxClose=()=>{
        setInfoModal(!infoModal)
    }

    const handleSaveProperty=async (id) =>{
        const valid = validation()
        if(valid){
            try {
                const options = {
                    "id": id,
                    "name": values.propertyName,
                    "alias": "wefre",
                    "description": "eregre",
                    "propertyCode": values.propertyCode,
                    "propertyCodeName": values.propertyCodeName,
                    "propertyTypeId": values.propertyType.value,
                    "statusId": 1,
                    "addressLine": values.propertyAddress,
                    "cityId": propertyCity.value,
                    "stateId": propertyState.value,
                    "countryId": propertyCountry.value,
                    "zipCode": "201306",
                    "locationId": propertyLocation.value,
                    "latitude": values.propertyLatitude,
                    "longitude": values.propertyLongitude,
                    "contactPersonName": values.propertyContactPerson,
                    "contactPersonPhoneNumber": values.propertyContactPersonMobile,
                    "serviceCharge": 0,
                    "serviceChargeType": 0,
                    "fssaiRegistrationNumber": null,
                    "gstNumber": "fdvfbvre",
                    "isActive": true,
                    "tenantId": 1
                    }
          
                const response = await postUser('api/Property/save', options)
                if(response){
                   setData(response.data.collections)
                   setPagingModel(response.data.pagingModel)
                  
                   fetchPropertyList(10, 1)
                   if(id === null){
                    setModal(!modal)
                   } else {
                    setEditModal(!editModal)
                   }
                  
                  
                }
              } catch (error) {
                console.error('Error fetching user:', error);
              }
        }
        
    }

    const fetchPropertyList = async (pagesize, currentPage) => {
        console.log(pagesize, currentPage);
        try {
            const options = {
                "pageSize": pagesize,
                "currentPage": currentPage,
                "name": "",
                "propertyCode": "",
                "propertyCodeName": "",
                "cityName": "",
                "locationName": "",
                "stateName": "",
                "tenantId": null,
                "propertyTypeId": null
              }
      
            const response = await postUser('api/Property/list', options)
            if(response){
               setData(response.data.collections)
               setPagingModel(response.data.pagingModel)
               setTotalCount(response.data.pagingModel.totalItems)
               setRowsPerPage(response.data.pagingModel.pageSize)
               paginationArr(response.data.pagingModel.totalItems, response.data.pagingModel.totalItems);
               setPagesize(pagesize)
            }
          } catch (error) {
            console.error('Error fetching user:', error);
          }
       
      }
      
      const fetchCountryData = async () => {
        try {
      
            const response = await getUser('/api/Master/countries')
            if(response){
            //    setCountryList(response.data)

               let newData = []
               response.data.forEach((data, i)=>{
                newData.push({
                    label : data.name,
                    value : data.id
                })
               })
               setCountryList(newData)
            }
          } catch (error) {
            console.error('Error fetching user:', error);
          }
       
      }
      const fetchStateData = async (id, rowData) => {
        try {
            if(id > 0){
                const response = await getUser(`api/Master/states/${id}`)
                if(response){
                    console.log('efnbgjktrngktr');
                //    setStateList(response.data)
                   let newData = []
                    response.data.forEach((data, i)=>{
                        newData.push({
                            label : data.name,
                            value : data.id
                        })
                        setStateList(newData)
                    })
                    

                    

                    if(Object.keys(rowData).length > 0){
                        const selectedStateID = newData.filter(x=> x.value === rowData.stateId)
                        setPropertyState(selectedStateID[0])
                    }
                   
                    
                }
            }
          } catch (error) {
            console.error('Error fetching user:', error);
          }
       
      }
      const fetchCityData = async (id, rowData) => {
        try {
            if(id > 0){
                const response = await getUser(`api/Master/cities/${id}`)
                if(response){
                //    setCitiesList(response.data)
                   let newData = []
                    response.data.forEach((data, i)=>{
                        newData.push({
                            label : data.name,
                            value : data.id
                        })
                    })
                    setCitiesList(newData)

                    if(Object.keys(rowData).length > 0){
                        const selectedCityID = newData.filter(x=> x.value === rowData.cityId)
                        setPropertyCity(selectedCityID[0])
                    }
                }
            }
          } catch (error) {
            console.error('Error fetching user:', error);
          }
       
      }
      const fetchLocationData = async (state, city, rowData) => {
        console.log(state, 'rkgnjrngre');
        console.log(city, 'fnnnbgtrjl');
        try {
            if(city > 0 && state > 0){
                
                const options = {
                    "stateid": state,
                    "cityid": city
                  }
          
                const response = await postUser('api/Master/locations', options)
                if(response.data.length > 0){
                    // setLocationList(response.data)
                    let newData = []
                    response.data.forEach((data, i)=>{
                        newData.push({
                            label : data.name,
                            value : data.id
                        })
                    })
                    setLocationList(newData)
                    if(Object.keys(rowData).length > 0){
                        const selectedLocationID = newData.filter(x=> x.value === rowData.locationId)
                        setPropertyLocation(selectedLocationID[0])
                    }
                }
            }
          } catch (error) {
            console.error('Error fetching user:', error);
          }
       
      }
      const fetchPropertyTypeData = async () => {
        try {
            const response = await getUser(`api/Master/property-types`)
                if(response){
                //    setPropertyTypeList(response.data)
                   let newData = []
                    response.data.forEach((data, i)=>{
                        newData.push({
                            label : data.name,
                            value : data.id
                        })
                    })
                    setPropertyTypeList(newData)
                }
          } catch (error) {
            console.error('Error fetching user:', error);
          }
       
      }

      const handleStatus=async(id, statusId, status)=>{
        // document.getElementById(statusId).classList.remove('text-success')
        //                 document.getElementById(statusId).classList.add('text-danger')

        try {

            if(window.confirm("Are you sure you want to change status?")){
                const response = await getUser(`api/Property/${id}/updatestatus/${status}`)
                fetchPropertyList(10, 1)
                }
              } catch (error) {
                console.error('Error fetching user:', error);
              }
        
      }

    


    useEffect(()=>{
        fetchPropertyList(10, 1)
        fetchCountryData()
        fetchPropertyTypeData()
    }, [props])

    const columns=[
        { title: '#', field: 'sno', headerStyle: { borderLeft: '0px', paddingLeft: 20, width: 50 } },
        { title: 'Name', field: 'name' },
        { title: 'Contact Preson', field: 'contactPersonName' },
        { title: 'Location', field: 'location' },
        { title: 'Status', field: 'status', align: 'center', render: rowData => <>
            {
                rowData.isActive ?
                <i class="bi bi-circle-fill text-success c-pointer" id={`status+${rowData.id}`} 
                onClick={()=>handleStatus(rowData.id, `status+${rowData.id}`, false)}></i> : <i class="bi bi-circle-fill text-danger" id={`status+${rowData.id}`} onClick={()=>handleStatus(rowData.id, `status+${rowData.id}`, true)}></i> 
            }
            
            
        </> },

        {
            title: 'Action', field: 'action', render: rowData => <>
                <span className='fw-bold d-block c-pointer' id={`${'morebuttonopen' + rowData.id}`} onClick={() => handleMore('morepannel' + rowData.id, 'morebuttonopen' + rowData.id, 'morebuttonclose' + rowData.id)}><MoreHorizIcon /></span>
                <span className='fw-bold d-none c-pointer' id={`${'morebuttonclose' + rowData.id}`} onClick={() => handleMoreClose('morepannel' + rowData.id, 'morebuttonopen' + rowData.id, 'morebuttonclose' + rowData.id,)}><MoreHorizIcon /></span>
                <div className='d-none morepannel' id={`${'morepannel' + rowData.id}`}>
                    <span className='d-block text-muted c-pointer' onClick={()=>handleOpenEditModal(rowData)} title='Edit'><i class="bi bi-pencil-square text-primary fs-small"></i> <small>Edit</small></span>
                    <span className='d-block mt-1 text-muted c-pointer' onClick={()=>handleInfoBox(rowData)} title='Info'><i class="bi bi-info-square text-dark  "></i> <small>Info</small></span>
                    <span className='d-block mt-1 text-muted c-pointer' title='Remove' onClick={()=>handleDeleteRow(rowData.id)}><i class="bi bi-trash text-danger fs-small"></i> <small>Remove</small></span>
                    <span className='d-block mt-1 text-muted c-pointer' title='Add'><i class="bi bi-plus-square text-primary fs-small"></i> <small> <Link to={{ pathname: '/sub-property-list', state: { id :rowData.id} }}>Sub Property</Link> </small></span>
                </div>
            </>
        },
    ]

   

    return (
        <>
        <PageTitle title={'Properties'} />
            <div className='card'>
                {console.log(paginationDDData, 'paginationDDData')}
                <div className='card-body mt-4'>
                    <span className='table-add-icon c-pointer' title='Add' onClick={handleOpenModal}><i class="bi bi-house-add"></i></span>
                    <span className='table-filter-icon c-pointer' title='Filter' onClick={handleOpenSlideBox}><i class="bi bi-funnel"></i></span>
                        {
                            pagingModel && pagingModel.currentPage >0 ?
                            // <CommonMaterialTable data={data} columns={columns} totalCount={pagingModel.totalItems}  rowsPerPage={pagingModel.pageSize}/> 
                            
                            <MaterialTable
                style={{ 'whiteSpace': "pre-wrap" }}
                title={''}
                icons={tableIcons}
                columns={columns}
                data={data}
                localization={{
                    toolbar: {
                        exportCSVName: "Export as Excel"
                    },
                    body: {
                        emptyDataSourceMessage: (
                           <div className='NorecordData'>
                            <p>No record found</p>
                            </div> )
                    },
                }}
                onChangePage={(currentPage) => {
                    console.log(currentPage, 'currentPage');
                    fetchPropertyList(10, currentPage+1 );
                }}
                onChangeRowsPerPage={(rows) => {
                    changeRowsNumber(rows)
                }}
                options={{
                    sorting: true,
                    exportButton: true,
                    rowStyle: x => {
                        if (x.tableData.id % 2) {
                            return { backgroundColor: "#f2f2f2" }
                        }
                    },
                    headerStyle: {
                        backgroundColor: '#fff',
                        color: '#000000',
                        border: '1px solid #dee2e6 ',
                        borderRight:'0px',
                        'font-weight': 'bold',
                    },
                    maxBodyHeight:'auto',
                    pageSize: rowsPerPage,
                    pageSizeOptions: paginationDDData,
                    // paging: totalCount && totalCount > 10 ? true :false,
                    
                }}
                components={{
                    Pagination: (props) => {
                        return <CustomPaginationComponent {...props} />;
                    }
                }}
            />
                            : null
                        }
                    
                    
                </div>
                
            </div>

        {
            isSlideModal ?
            <div className='slider-box'>
            <div className='slider-box-title'>
            <span className='text-primary'><b>Filter</b></span>
                <span className='text-danger fw-bold c-pointer float-end' onClick={handleCloseSlideBox}>X</span>
            </div>
            <div className='slider-box-body'>
                <Label className='text-muted fw-bold'>Name</Label>
                <Input type='text' value='' name='' onChange={handleChange} />
                <Label className='mt-3 text-muted fw-bold'>Location</Label>
                <Input type='text' value='' name='' onChange={handleChange} />
                <Label className='mt-3 text-muted fw-bold'>Status</Label>
                <Input type='select' value='' name='' onChange={handleChange} >
                    <option value=''>Select</option>
                    <option value='Available'>Available</option>
                    <option value='Booked'>Booked</option>
                    <option value='Block'>Block</option>
                </Input>
            </div>
            <div className='slider-box-footer text-center mt-4'>
                <Button color='primary' block>Filter</Button>
            </div>
        </div> : null
        }

        <Modal isOpen={modal} size='xl' >
            <ModalHeader data-bs-theme="dark" toggle={handleCloseModal}>Add Property</ModalHeader>
            <ModalBody>
            
                <Row>
                    <Col sm={12}>
                        <Label>Property Name</Label>
                        <Input value={values.propertyName} name='propertyName' onChange={handleChange('propertyName')} />
                        <span className='text-danger mb-2 d-block'>{errors['propertyName_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Property Code</Label>
                        <Input value={values.propertyCode} name='propertyCode' onChange={handleChange('propertyCode')} />
                        <span className='text-danger mb-2 d-block'>{errors['propertyCode_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Property Code Name</Label>
                        <Input value={values.propertyCodeName} name='propertyCodeName' onChange={handleChange('propertyCodeName')} />
                        <span className='text-danger mb-2 d-block'>{errors['propertyCodeName_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Contact Person</Label>
                        <Input value={values.propertyContactPerson} name='propertyContactPerson' onChange={handleChange('propertyContactPerson')} />
                        <span className='text-danger mb-2 d-block'>{errors['propertyContactPerson_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Contact Person Mobile</Label>
                        <Input type='number' maxLength={12} value={values.propertyContactPersonMobile} name='propertyContactPersonMobile' onChange={handleChange('propertyContactPersonMobile')} />
                        <span className='text-danger mb-2 d-block'>{errors['propertyContactPersonMobile_error']}</span>
                    </Col>
                    <Col sm={4}>
                        <Label>Country</Label>
                        
                        <SelectCC
                            value={propertyCountry}
                            onChange={handleChangeCountry}
                            label="Single select"
                            options={countryList}
                            />
                        <span className='text-danger mb-2 d-block'>{errors['propertyCountry_error']}</span>
                    </Col>
                    <Col sm={4}>
                        <Label>State</Label>
                        
                        <SelectCC
                            value={propertyState}
                            onChange={handleChangeState}
                            label="Single select"
                            options={stateList}
                            />
                        <span className='text-danger mb-2 d-block'>{errors['propertyState_error']}</span>
                    </Col>
                    <Col sm={4}>
                        <Label>City</Label>
                        
                            <SelectCC
                            value={propertyCity}
                            onChange={handleChangeCity}
                            label="Single select"
                            options={citiesList}
                            />
                        <span className='text-danger mb-2 d-block'>{errors['propertyCity_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Location</Label> 
                        
                            <SelectCC
                            value={propertyLocation}
                            onChange={handleChangeLocation}
                            label="Single select"
                            options={locationList}
                            />
                        <span className='text-danger mb-2 d-block'>{errors['propertyLocation_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Property Type</Label>
                        
                        <SelectCC
                            value={values.propertyType}
                            onChange={handleChangeType}
                            label="Single select"
                            options={propertyTypeList}
                            />
                        <span className='text-danger mb-2 d-block'>{errors['propertyType_error']}</span>
                    </Col>
                    <Col sm={12}>
                        <Label>Address</Label>
                        <Input value={values.propertyAddress} name='propertyAddress' onChange={handleChange('propertyAddress')} />
                        <span className='text-danger mb-2 d-block'>{errors['propertyAddress_error']}</span>
                    </Col>
                    <Col sm={12}>
                        <Label>Select Location</Label>
                        <MapContainer center={position} zoom={13} scrollWheelZoom={false} onClick={handleClick}>
                            <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <LocationMarker />
                            <LeafletgeoSearch/>
                        </MapContainer>
                    </Col>
                    <Col sm={6}>
                        <Label>Latitude</Label>
                        <Input value={values.propertyLatitude} name='propertyLatitude' onChange={handleChange('propertyLatitude')} />
                        <span className='text-danger mb-2 d-block'>{errors['propertyLatitude_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Longitude</Label>
                        <Input value={values.propertyLongitude} name='propertyLongitude' onChange={handleChange('propertyLongitude')} />
                        <span className='text-danger mb-2 d-block'>{errors['propertyLongitude_error']}</span>
                    </Col>
                    

                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color='danger' className='mr-1'>Cancel</Button>
                <Button color='primary' onClick={()=>handleSaveProperty(null)}>Add Property</Button>
            </ModalFooter>
        </Modal>
        <Modal isOpen={editModal} size='xl'>
            <ModalHeader data-bs-theme="dark" toggle={handleCloseEditModal}>Edit Property</ModalHeader>
            <ModalBody>
            
                <Row>
                    <Col sm={12}>
                        <Label>Property Name</Label>
                        <Input value={values.propertyName} name='propertyName' onChange={handleChange('propertyName')} />
                        <span className='text-danger mb-2 d-block'>{errors['propertyName_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Property Code</Label>
                        <Input value={values.propertyCode} name='propertyCode' onChange={handleChange('propertyCode')} />
                        <span className='text-danger mb-2 d-block'>{errors['propertyCode_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Property Code Name</Label>
                        <Input value={values.propertyCodeName} name='propertyCodeName' onChange={handleChange('propertyCodeName')} />
                        <span className='text-danger mb-2 d-block'>{errors['propertyCodeName_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Contact Person</Label>
                        <Input value={values.propertyContactPerson} name='propertyContactPerson' onChange={handleChange('propertyContactPerson')} />
                        <span className='text-danger mb-2 d-block'>{errors['propertyContactPerson_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Contact Person Mobile</Label>
                        <Input value={values.propertyContactPersonMobile} name='propertyContactPersonMobile' onChange={handleChange('propertyContactPersonMobile')} />
                        <span className='text-danger mb-2 d-block'>{errors['propertyContactPersonMobile_error']}</span>
                    </Col>
                    <Col sm={4}>
                        <Label>Country</Label>
                        
                        <SelectCC
                            value={propertyCountry}
                            onChange={handleChangeCountry}
                            label="Single select"
                            options={countryList}
                            />
                        <span className='text-danger mb-2 d-block'>{errors['propertyCountry_error']}</span>
                    </Col>
                    <Col sm={4}>
                        <Label>State</Label>
                        
                        <SelectCC
                            value={propertyState}
                            onChange={handleChangeState}
                            label="Single select"
                            options={stateList}
                            />
                        <span className='text-danger mb-2 d-block'>{errors['propertyState_error']}</span>
                    </Col>
                    <Col sm={4}>
                        <Label>City</Label>
                        
                            <SelectCC
                            value={propertyCity}
                            onChange={handleChangeCity}
                            label="Single select"
                            options={citiesList}
                            />
                        <span className='text-danger mb-2 d-block'>{errors['propertyCity_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Location</Label> 
                        
                            <SelectCC
                            value={propertyLocation}
                            onChange={handleChangeLocation}
                            label="Single select"
                            options={locationList}
                            />
                        <span className='text-danger mb-2 d-block'>{errors['propertyLocation_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Property Type</Label>
                        
                        <SelectCC
                            value={values.propertyType}
                            onChange={handleChangeType}
                            label="Single select"
                            options={propertyTypeList}
                            />
                        <span className='text-danger mb-2 d-block'>{errors['propertyType_error']}</span>
                    </Col>
                    <Col sm={12}>
                        <Label>Address</Label>
                        <Input value={values.propertyAddress} name='propertyAddress' onChange={handleChange('propertyAddress')} />
                        <span className='text-danger mb-2 d-block'>{errors['propertyAddress_error']}</span>
                    </Col>
                    <Col sm={12}>
                        <Label>Select Location</Label>
                        <MapContainer center={position} zoom={13} scrollWheelZoom={false} onClick={handleClick}>
                            <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <LocationMarker />
                            <LeafletgeoSearch/>
                        </MapContainer>
                    </Col>
                    <Col sm={6}>
                        <Label>Latitude</Label>
                        <Input value={values.propertyLatitude} name='propertyLatitude' onChange={handleChange('propertyLatitude')} />
                        <span className='text-danger mb-2 d-block'>{errors['propertyLatitude_error']}</span>
                    </Col>
                    <Col sm={6}>
                        <Label>Longitude</Label>
                        <Input value={values.propertyLongitude} name='propertyLongitude' onChange={handleChange('propertyLongitude')} />
                        <span className='text-danger mb-2 d-block'>{errors['propertyLongitude_error']}</span>
                    </Col>
                    

                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color='danger' className='mr-1'>Cancel</Button>
                <Button color='primary' onClick={()=>handleSaveProperty(selectedRowID.id)} >Save</Button>
            </ModalFooter>
        </Modal>

        <Modal isOpen={infoModal} size='lg'>
            <ModalHeader data-bs-theme="dark" toggle={handleInfoBoxClose}>{selectedRowID.name} <small>{selectedRowID.propertyCode} ( {selectedRowID.propertyCodeName} )</small></ModalHeader>
            <ModalBody className='text-center'>
                <Row>
                    <Col sm={12}>
                        <small className='text-start d-block mb-2'>{selectedRowID.addressLine}</small>
                    </Col>
                    <Col sm={4} className='border-end'>
                        <small>Contact Preson</small>
                        <p>{selectedRowID.contactPersonName}</p>
                    </Col>
                    <Col sm={4} className='border-end'>
                        <small>Contact Preson Mobile</small>
                        <p>{selectedRowID.contactPersonPhoneNumber}</p>
                    </Col>
                    <Col sm={4}>
                        <small>Location</small>
                        <p>{selectedRowID.location}</p>
                    </Col>
                    
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color='danger' className='mr-1' onClick={handleInfoBoxClose}>Cancel</Button>
            </ModalFooter>
        </Modal>

        </>
    )

}

export default PropertyList;