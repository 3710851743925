import React from 'react';
import { Doughnut } from 'react-chartjs-2';



const labels = ['January', 'February'];

const dataddddd = {
    labels: [
        'Red',
        'Blue',
        'Yellow'
      ],
      datasets: [{
        label: 'My First Dataset',
        data: [300, 50, 100],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(255, 205, 86)'
        ],
        hoverOffset: 4
      }]
  }


const Report=()=>{

    return (
        <>
            <Doughnut
            // width={300}
            // height={300}
              data={dataddddd}
              options={{
                cutoutPercentage: 70,
                responsive: false,
                legend: {
                    position: 'bottom',
                    display: true,
                    labels: {
                        fontSize: 8,
                        usePointStyle: true,
                        boxWidth: 6
                    }
                  },
                layout: {
                    padding: {
                       right: 20
                    }
                 },
                plugins: {
                    legend: {
                        display: false,
                        position: "bottom",
                        labels: {
                            fontSize: 8,
                            usePointStyle: true,
                            boxWidth: 6
                        }
                    }
                }
            }}
            />
        </>
    )

}

export default Report;
